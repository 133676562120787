import React from 'react';
import {useRecoilState} from 'recoil';
import InputBase from '@components/input/InputBase';
import {loginFormState} from "@pages/auth/login/atoms";
import {searchFilterState, userFilterState} from "@pages/userManage/atoms";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    type: string;
    name: string;
    placeholder: string;
    maxLength: number
};

const Input: React.FC<Props> = ({type, name, placeholder, maxLength, ...props}: Props) => {
    const [state, setState] = useRecoilState(searchFilterState);

    return (
        <div className={'form-group'}>
            <InputBase
                {...props}
                className="auth_input"
                maxLength={maxLength}
                type={type}
                name={name}
                placeholder={placeholder}
                state={state}
                setState={setState}
            />
        </div>
    );
};

export default Input;
