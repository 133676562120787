import {AuthorizeHttpClient} from "@/main/api/authorize-http-client-decorator";
import {API_ADDRESS} from "@/main/api/ApiFactory";
import {HttpStatusCode} from "@/main/api/http-client";
import {ApiError} from "@/domain/model/errors/ApiError";
import {IBaseResponse} from "@/domain/model/response/base-response-model";
import {UnexpectedError} from "@/domain/model/errors/unexpected-error";
import {LocationRequest} from "@/domain/model/request/LocationRequest";
import {LocationResponse} from "@/domain/model/response/LocationResponse";
import {LocationDetailResponse} from "@/domain/model/response/LocationDetailResponse";
import {CreateLocationRequest} from "@/domain/model/request/CreateLocationRequest";

export const getLocations = async (params: LocationRequest): Promise<IBaseResponse<LocationResponse>> => {
    let checkNullParams = {...params}
    if (params.siDo === "") checkNullParams.siDo = null;
    if (params.siGunGu === "") checkNullParams.siGunGu = null;
    if (params.word === "") checkNullParams.word = null;

    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/address/list',
        method: 'get',
        params: checkNullParams
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<LocationResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}


export const getSido = async (siDo: string): Promise<IBaseResponse<string[]>> => {

    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/siDoSiGunGu',
        method: 'get',
        params: siDo ? {siDo} : null
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<string[]>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}


export const getLocationDetail = async (lcId: number): Promise<IBaseResponse<LocationDetailResponse>> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/address/${lcId}`,
        method: 'get',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<LocationDetailResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const createLocation = async (request: CreateLocationRequest): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/address`,
        method: 'post',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const updateLocation = async (lcId: number, request: CreateLocationRequest): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/address/${lcId}`,
        method: 'put',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}


export const deleteLocation = async (bCode: string): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/address/${bCode}`,
        method: 'delete',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const deleteMultiLocation = async (codes: string[]): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/address`,
        method: 'delete',
        body: {codes}
    })

    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

