import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import React from 'react';
import {RouteIf} from "@/main/routes/RouteIf";
import LoginPage from "@pages/auth/login/LoginPage";
import UserManagePage from "@pages/userManage/UserManagePage";
import UserManageDetailPage from "@pages/userManage/detail/UserManageDetailPage";
import UserModifyPage from "@pages/userManage/detail/UserModifyPage";
import UserAddPage from "@pages/userManage/UserAddPage";
import SalesPostPage from "@pages/salesPost/SalesPostPage";
import SalesPostDetailPage from "@pages/salesPost/detail/SalesPostDetailPage";
import SalesPostModifyPage from "@pages/salesPost/detail/SalesPostModifyPage";
import SalesPostCreatePage from "@pages/salesPost/SalesPostCreatePage";
import QuestionManagePage from "@pages/question/QuestionManagePage";
import QuestionDetailPage from "@pages/question/detail/QuestionDetailPage";
import InterestItemManagePage from "@pages/interestItem/InterestItemManagePage";
import InterestItemDetailPage from "@pages/interestItem/detail/InterestItemDetailPage";
import LocationManagePage from "@pages/location/LocationManagePage";
import LocationDetailPage from "@pages/location/detail/LocationDetailPage";
import LocationModifyPage from "@pages/location/detail/LocationModifyPage";
import LocationCreatePage from "@pages/location/LocationCreatePage";


const Router: React.FC = () => (
    <RecoilRoot>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <RouteIf option={true}>
                        {/*<LoginPage/>*/}
                        <UserManagePage/>
                    </RouteIf>
                }/>
                <Route path="/login" element={
                    <RouteIf option={false}>
                        <LoginPage/>
                    </RouteIf>
                }/>
                <Route path={"/user"}>
                    <Route path="add" element={
                        <RouteIf option={true}>
                            <UserAddPage/>
                        </RouteIf>
                    }/>
                    <Route path=":no" element={
                        <RouteIf option={true}>
                            <UserManageDetailPage/>
                        </RouteIf>
                    }/>
                    <Route path=":no/modify" element={
                        <RouteIf option={true}>
                            <UserModifyPage/>
                        </RouteIf>
                    }/>
                </Route>
                <Route path={"/salesPost"}>
                    <Route path="" element={
                        <RouteIf option={true}>
                            <SalesPostPage/>
                        </RouteIf>
                    }/>
                    <Route path="create" element={
                        <RouteIf option={true}>
                            <SalesPostCreatePage/>
                        </RouteIf>
                    }/>
                    <Route path=":no" element={
                        <RouteIf option={true}>
                            <SalesPostDetailPage/>
                        </RouteIf>
                    }/>
                    <Route path=":no/modify" element={
                        <RouteIf option={true}>
                            <SalesPostModifyPage/>
                        </RouteIf>
                    }/>
                </Route>

                <Route path={"/question"}>
                    <Route path="" element={
                        <RouteIf option={true}>
                            <QuestionManagePage/>
                        </RouteIf>
                    }/>
                    <Route path=":no" element={
                        <RouteIf option={true}>
                            <QuestionDetailPage/>
                        </RouteIf>
                    }/>
                </Route>
                {/*<Route path={"/interestItem"}>*/}
                {/*    <Route path="" element={*/}
                {/*        <RouteIf option={true}>*/}
                {/*            <InterestItemManagePage/>*/}
                {/*        </RouteIf>*/}
                {/*    }/>*/}
                {/*    <Route path=":no" element={*/}
                {/*        <RouteIf option={true}>*/}
                {/*            <InterestItemDetailPage/>*/}
                {/*        </RouteIf>*/}
                {/*    }/>*/}
                {/*</Route>*/}
                <Route path={"/location"}>
                    <Route path="" element={
                        <RouteIf option={true}>
                            <LocationManagePage/>
                        </RouteIf>
                    }/>
                    <Route path="create" element={
                        <RouteIf option={true}>
                            <LocationCreatePage/>
                        </RouteIf>
                    }/>
                    <Route path=":no" element={
                        <RouteIf option={true}>
                            <LocationDetailPage/>
                        </RouteIf>
                    }/>
                    <Route path=":no/modify" element={
                        <RouteIf option={true}>
                            <LocationModifyPage/>
                        </RouteIf>
                    }/>
                </Route>
            </Routes>
        </BrowserRouter>
    </RecoilRoot>
);

export default Router;
