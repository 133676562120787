import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Select from "@pages/salesPost/form/modify/Select";
import {boardTypeOptions, tradeTypeOptions} from "@/presentation/data/options";
import Input from "@pages/salesPost/form/modify/Input";
import {BasePage} from "@components/page/BasePage";
import {useRecoilState, useResetRecoilState} from "recoil";
import {updateSalesPostFormState} from "@pages/salesPost/atoms";
import {presentationError} from "@/presentation/errors/presentationError";
import {getSalesPostDetail, updateSalesPost, updateSalesPostImage} from "@/domain/repository/SalesPostRepository";
import {notificationAlert} from "@/presentation/helpers/alert";


const SalesPostModifyPage: React.FC = () => {
    const navigator = useNavigate();
    const {no} = useParams<{ no: string }>();
    const [images, setImages] = useState<string[]>([]);
    const [scalesPostForm, setSalesPostForm] = useRecoilState(updateSalesPostFormState);

    const resetFormData = useResetRecoilState(updateSalesPostFormState);

    useEffect(() => {
        resetFormData();
    }, [])

    useEffect(() => {
        if (no) {
            getPostDetail(parseInt(no));
        }
    }, [no])

    const getPostDetail = async (postId: number) => {
        try {
            const result = await getSalesPostDetail(postId);

            if (result.success && result.response) {
                const resData = result.response;
                setImages(resData.images);
                setSalesPostForm(old => ({
                    ...old,
                    type: resData.facilityType,
                    itemType: resData.itemType,
                    address: resData.bcode,
                    itemState: resData.itemState,
                    phoneNumber: resData.phoneNumber,
                    mainAddress: resData.mainAddress,
                    detailAddress: resData.detailAddress,
                    price: resData.price,
                    keyMoney: resData.keyMoney,
                    maintenanceFee: resData.maintenanceFee,
                    monthlyRent: resData.monthlyRent,
                    description: resData.description,
                    silPyeongSu: resData.silPyeongSu,
                    jeonYong: resData.jeonYong,
                }));
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '매물 정보 가져오기',
                alert: true
            });
        }
    }

    const uploadFile = async (file: FileList | null) => {
        try {
            if (!no) return;
            if (file) {
                if ((file.length > 10) || ((images.length + file.length) > 10)) {
                    notificationAlert('알림', '이미지는 최대 10개만 등록가능 합니다.');
                    return;
                }
                const result = await updateSalesPostImage(no, [], images, Array.from(file));

                if (result.success) {
                    getPostDetail(parseInt(no));
                    notificationAlert('알림', '이미지 업데이트가 완료되었습니다.');
                }

            }
        } catch (err) {
            presentationError({
                error: err,
                type: '매물 이미지 업데이트',
                alert: true
            });
        }
    }

    const deleteFile = async (fileURL: string) => {
        try {
            if (!no) return;
            if (fileURL) {

                const notRemoveImages = images.filter(img => img !== fileURL);
                const result = await updateSalesPostImage(no, [fileURL], [...notRemoveImages], []);

                if (result.success) {
                    setImages(notRemoveImages);
                    notificationAlert('알림', '이미지 삭제가 완료되었습니다.');
                }

            }
        } catch (err) {
            presentationError({
                error: err,
                type: '매물 이미지 업데이트',
                alert: true
            });
        }
    }


    const handleModifyPost = async () => {
        try {
            if (!no) return;
            const result = await updateSalesPost(parseInt(no), scalesPostForm);

            if (result.success) {
                notificationAlert('알림', '업데이트 되었습니다.');
                navigator(-1);
            }

        } catch (err) {
            presentationError({
                error: err,
                type: '매물 정보 업데이트',
                alert: true
            });
        }
    }


    return (
        <BasePage title={'판매글 수정'}>
            <div className={'sales_post_modify_page info_tab'}>
                <ul className={'info_list'}>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                거래종류
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Select name={'itemType'} options={tradeTypeOptions}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                게시글 상태
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Select name={'itemState'} options={boardTypeOptions}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                판매자ID(휴대폰전화)
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'phoneNumber'} placeholder={'-없이 입력해 주세요.'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                매물위치(지역코드)
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'address'} placeholder={'매물위치(지역코드)'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                상세매물위치
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'detailAddress'} placeholder={'상세매물위치'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                사진
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <input type={'file'}
                                       accept="image/*"
                                       multiple={false}
                                       onChange={event => uploadFile(event.target.files)}/>
                                <div className={'img_list_wrapper'}>
                                    {images.map(img => <p>{img}
                                        <button onClick={() => deleteFile(img)} className={'close'}/>
                                    </p>)}
                                </div>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                소개글
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'description'} placeholder={'소개글'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                매매가
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled={scalesPostForm.itemType !== 'SALE'} type={'number'} name={'price'}
                                       placeholder={'매매가'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                전세
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled={scalesPostForm.itemType !== 'JEONSE'} type={'number'} name={'price'}
                                       placeholder={'전세'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                월세
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled={!(scalesPostForm.itemType === 'MONTHLY')} type={'number'}
                                       name={'monthlyRent'} placeholder={'월세'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                보증금
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled={!(scalesPostForm.itemType === 'MONTHLY')} type={'number'}
                                       name={'price'} placeholder={'보증금'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                권리금
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'number'} name={'keyMoney'} placeholder={'권리금'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                관리비
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'number'} name={'maintenanceFee'} placeholder={'관리비'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                실 평수
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'number'} name={'silPyeongSu'} placeholder={'실 평수'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                </ul>
                <div className={'center_bottom_btn'}>
                    <button onClick={handleModifyPost}>수정</button>
                    <button onClick={() => navigator(-1)}>취소</button>
                </div>
            </div>
        </BasePage>
    )
}


export default SalesPostModifyPage;