import {AuthorizeHttpClient} from "@/main/api/authorize-http-client-decorator";
import {API_ADDRESS} from "@/main/api/ApiFactory";
import {HttpStatusCode} from "@/main/api/http-client";
import {ApiError} from "@/domain/model/errors/ApiError";
import {IBaseResponse} from "@/domain/model/response/base-response-model";
import {UnexpectedError} from "@/domain/model/errors/unexpected-error";
import {MemberResponse} from "@/domain/model/response/MemberResponse";
import {MemberRequest} from "@/domain/model/request/MemberRequest";
import {MemberDetailResponse} from "@/domain/model/response/MemberDetailResponse";
import {UpdateMemberRequest} from "@/domain/model/request/UpdateMemberRequest";
import {CreateMemberRequest} from "@/domain/model/request/CreateMemberRequest";
import {SalesPostRequest} from "@/domain/model/request/SalesPostRequest";
import {SalesPostResponse} from "@/domain/model/response/SalesPostResponse";
import {SalesPostDetailResponse} from "@/domain/model/response/SalesPostDetailResponse";
import {CreateSalesPostRequest} from "@/domain/model/request/CreateSalesPostRequest";
import {isIterableArray} from "@/presentation/helpers/utility";
import {UpdateSalesPostRequest} from "@/domain/model/request/UpdateSalesPostRequest";

export const getSalesPosts = async (params: SalesPostRequest): Promise<IBaseResponse<SalesPostResponse>> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/item/all',
        method: 'get',
        params: params
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<SalesPostResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const getSalesPostDetail = async (postId: number): Promise<IBaseResponse<SalesPostDetailResponse>> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/item/${postId}`,
        method: 'get',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<SalesPostDetailResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const createSalesPost = async (request: CreateSalesPostRequest): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const formData = new FormData();
    Object.keys(request).forEach(req => {
        if (req === 'images') {
            if (request.images) {
                if (isIterableArray(request.images)) {
                    for (const file of request.images) {
                        formData.append("images", file);
                    }
                }
            }

        } else {
            formData.append(req, request[req]);
        }
    })

    const httpResponse = await client.request({
        url: API_ADDRESS + `/item`,
        method: 'post',
        body: formData
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const updateSalesPost = async (itemId: number, request: UpdateSalesPostRequest): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/item/${itemId}`,
        method: 'put',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const updateSalesPostImage = async (postId: string, removeImages: string[], notRemoveImages: string[], newImageFiles: File[]): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const formData = new FormData();
    formData.append('itemId', postId);
    for (const file of removeImages) {
        formData.append('removeImages', file);
    }
    for (const file of notRemoveImages) {
        formData.append('notRemoveImages', file);
    }
    for (const file of newImageFiles) {
        formData.append('newImageFiles', file);
    }

    const httpResponse = await client.request({
        url: API_ADDRESS + `/item/image/${postId}`,
        method: 'put',
        body: formData
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}


export const deleteSalesPost = async (postId: number): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/item/${postId}`,
        method: 'delete',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const deleteMultiSalesPost = async (postIds: number[]): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/item`,
        method: 'delete',
        body: {itemIds: postIds}
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

