import React from "react";

interface IProps {
    width: number
    height: number
    fill: string
}

export const CartIcon: React.FC<IProps> = ({width, height, fill}) => (
    <svg version="1.1"
         id="Capa_1"
         xmlns="http://www.w3.org/2000/svg"
         width={`${width}px`}
         height={`${height}px`}
         fill={fill}
         x="0px"
         y="0px"
         viewBox="0 0 511.997 511.997">
        <g>
            <g>
                <path d="M405.387,362.612c-35.202,0-63.84,28.639-63.84,63.84s28.639,63.84,63.84,63.84s63.84-28.639,63.84-63.84
			S440.588,362.612,405.387,362.612z M405.387,451.988c-14.083,0-25.536-11.453-25.536-25.536s11.453-25.536,25.536-25.536
			c14.083,0,25.536,11.453,25.536,25.536S419.47,451.988,405.387,451.988z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M507.927,115.875c-3.626-4.641-9.187-7.348-15.079-7.348H118.22l-17.237-72.12c-2.062-8.618-9.768-14.702-18.629-14.702
			H19.152C8.574,21.704,0,30.278,0,40.856s8.574,19.152,19.152,19.152h48.085l62.244,260.443
			c2.062,8.625,9.768,14.702,18.629,14.702h298.135c8.804,0,16.477-6.001,18.59-14.543l46.604-188.329
			C512.849,126.562,511.553,120.516,507.927,115.875z M431.261,296.85H163.227l-35.853-150.019h341.003L431.261,296.85z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M173.646,362.612c-35.202,0-63.84,28.639-63.84,63.84s28.639,63.84,63.84,63.84s63.84-28.639,63.84-63.84
			S208.847,362.612,173.646,362.612z M173.646,451.988c-14.083,0-25.536-11.453-25.536-25.536s11.453-25.536,25.536-25.536
			s25.536,11.453,25.536,25.536S187.729,451.988,173.646,451.988z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>


)