import {atom} from 'recoil'
import {SalesPostRequest} from "@/domain/model/request/SalesPostRequest";
import {SalesPostResponse} from "@/domain/model/response/SalesPostResponse";
import {SalesPostDetailResponse} from "@/domain/model/response/SalesPostDetailResponse";
import {CreateSalesPostRequest} from "@/domain/model/request/CreateSalesPostRequest";
import {UpdateSalesPostRequest} from "@/domain/model/request/UpdateSalesPostRequest";
import {v1} from 'uuid';

interface ISearchFilter {
    type?: string
    state?: string
    itemSearchType?: string
    word?: string
    startSignUp?: string
    endSignUp?: string
}

export const searchFilterState = atom<ISearchFilter>({
    key: `searchFilterState/${v1()}`,
    default: {
        type: 'ALL',
        state: 'ALL',
        itemSearchType: 'ALL',
    }
})

export const salesPostFilterState = atom<SalesPostRequest>({
    key: `salesPostFilterState/${v1()}`,
    default: {
        type: 'ALL',
        state: 'ALL',
        itemSearchType: 'ALL',
        page: 1,
        size: 10,
        itemSortType: 'DESC'
    }
})

export const salesPostListState = atom<SalesPostResponse>({
    key: `salesPostListState/${v1()}`,
    default: {
        nowPage: 1,
        lastPage: 1,
        totalCnt: 0,
        items: []
    }
})


export const createSalesPostFormState = atom<CreateSalesPostRequest>({
    key: `createSalesPostFormState/${v1()}`,
    default: {
        type: '',
        itemType: 'SALE',
        itemState: 'WAIT',
        price: 0,
        phoneNumber: '',
        address: '',
        mainAddress: ''
    }
})

export const salesPostDetailState = atom<SalesPostDetailResponse>({
    key: `salesPostDetailState/${v1()}`,
    default: {
        itemId: 0,
        facilityType: '',
        itemType: '',
        itemState: '',
        phoneNumber: '',
        mainAddress: '',
        detailAddress: '',
        images: [],
        description: '',
        price: 0,
        keyMoney: 0,
        maintenanceFee: 0,
        monthlyRent: 0,
        silPyeongSu: 0,
        jeonYong: 0,
        bcode: '',
        views: 0,
        likeCnt: 0
    }
});


export const updateSalesPostFormState = atom<UpdateSalesPostRequest>({
    key: `updateSalesPostFormState/${v1()}`,
    default: {
        type: '',
        itemType: '',
        itemState: '',
        phoneNumber: '',
        address: '',
        mainAddress: '',
        detailAddress: '',
        price: 0,
        keyMoney: 0,
        maintenanceFee: 0,
        monthlyRent: 0,
        latitude: '',
        longitude: '',
        description: '',
        silPyeongSu: 0,
        jeonYong: 0,
    }
})