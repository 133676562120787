export class ApiError extends Error {
    readonly errorCode: number
    readonly message: string

    constructor (errorCode: number, message: string) {
        super('Api Error')
        this.name = 'Api Error'
        this.errorCode = errorCode
        this.message = message
    }
}
