import {atom} from 'recoil'
import {Member, MemberResponse} from "@/domain/model/response/MemberResponse";
import {MemberRequest} from "@/domain/model/request/MemberRequest";
import {MemberDetailResponse} from "@/domain/model/response/MemberDetailResponse";
import {CreateMemberRequest} from "@/domain/model/request/CreateMemberRequest";
import {MemberItemResponse} from "@/domain/model/response/MemberItemResponse";
import {MemberItemRequest} from "@/domain/model/request/MemberItemRequest";
import {v1} from "uuid";

interface ISearchFilter {
    state?: string
    searchType?: string
    word?: string
    startSignUp?: string
    endSignUp?: string
    startLastLogin?: string
    endLastLogin?: string
}

export const searchFilterState = atom<ISearchFilter>({
    key: `searchFilterState/${v1()}`,
    default: {
        state: 'ALL',
        searchType: 'ALL'
    }
})

export const userFilterState = atom<MemberRequest>({
    key: `userFilterState/${v1()}`,
    default: {
        state: 'ALL',
        searchType: 'ALL',
        page: 1,
        size: 10,
        sortType: 'DESC',
        sortName: 'ID'
    }
})

export const memberListState = atom<MemberResponse>({
    key: `memberListState/${v1()}`,
    default: {
        nowPage: 1,
        lastPage: 1,
        totalCnt: 0,
        members: []
    }
});


export const memberDetailState = atom<MemberDetailResponse>({
    key: `memberDetailState/${v1()}`,
    default: {
        memberId: 0,
        phoneNumber: '',
        name: '',
        nickName: '',
        grade: '',
        memberState: '',
        signUpDate: '',
        lastLoginDate: ''
    }
})


interface IUpdateMemberForm {
    name: string
    phoneNumber: string
    nickname: string
    password?: string
    passwordConfirm?: string
    grade: string
    memberState: string
    sanctionComment?: string
    startSanctionDate?: string
    endSanctionDate?: string
}

export const memberUpdateState = atom<IUpdateMemberForm>({
    key: `memberUpdateState/${v1()}`,
    default: {
        name: '',
        phoneNumber: '',
        nickname: '',
        grade: '',
        memberState: ''
    }
});

export const createMemberFormState = atom<CreateMemberRequest>({
    key: `createMemberFormState/${v1()}`,
    default: {
        name: '',
        phoneNumber: '',
        nickname: '',
        password: '',
        passwordConfirm: '',
        grade: '',
    }
})


interface ISearchItemFilter {
    type?: string
    state?: string
    itemSearchType?: string
    word?: string
}

export const searchItemFilterState = atom<ISearchItemFilter>({
    key: `searchItemFilterState/${v1()}`,
    default: {
        type: 'ALL',
        state: 'ALL',
        itemSearchType: 'ALL'
    }
})

export const memberItemFilterState = atom<MemberItemRequest>({
    key: `memberItemFilterState/${v1()}`,
    default: {
        type: 'ALL',
        state: 'ALL',
        itemSearchType: 'ALL',
        page: 1,
        size: 10,
        sortType: 'DESC',
        // itemSortName: 'ID'
    }
})


export const memberItemListState = atom<MemberItemResponse>({
    key: `memberItemListState/${v1()}`,
    default: {
        nowPage: 1,
        lastPage: 1,
        totalCnt: 0,
        items: []
    }
});