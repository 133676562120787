import React from "react";
import Select from "@pages/userManage/form/itemFilter/Select";
import {
    filterBoardTypeOptions, filterSearchItemTypeOptions,
    filterSearchTypeOptions,
    filterTradeTypeOptions
} from "@/presentation/data/options";
import Input from "@pages/userManage/form/itemFilter/Input";
import {Col, Row} from "react-bootstrap";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {
    memberItemFilterState,
    searchItemFilterState,
} from "@pages/userManage/atoms";


const TableFilter: React.FC = () => {

    const resetFilter = useResetRecoilState(memberItemFilterState);
    const resetSearchFilter = useResetRecoilState(searchItemFilterState);
    const setFilter = useSetRecoilState(memberItemFilterState);
    const searchFilter = useRecoilValue(searchItemFilterState);

    const handleSearch = () => {
        setFilter(old => ({
            ...old,
            ...searchFilter
        }))
    }

    const handleReset = () => {
        resetFilter();
        resetSearchFilter();
    }

    return (
        <div className={'table_filter'}>
            <ul className={'filter_options'}>
                <li>
                    <div className={'option_name'}>거래종류</div>
                    <Select name={'type'} options={filterTradeTypeOptions}/>
                </li>
                <li>
                    <div className={'option_name'}>게시글 상태</div>
                    <Select name={'state'} options={filterBoardTypeOptions}/>
                </li>
                <li>
                    <div className={'option_name'}>검색어</div>
                    <Row className={'flex-grow-1'}>
                        <Col xs={3} style={{paddingRight: 0}}>
                            <Select name={'itemSearchType'} options={filterSearchItemTypeOptions}/>
                        </Col>
                        <Col xs={9} style={{paddingLeft: 0}}>
                            <Input type={'text'} name={'word'} placeholder={'검색어'} maxLength={255}/>
                        </Col>
                    </Row>
                </li>
            </ul>
            <div className={'filter_btns'}>
                <button id={'search_btn'} onClick={handleSearch}>검색</button>
                <button id={'init_btn'} onClick={handleReset}>초기화</button>
            </div>
        </div>
    )
}

export default TableFilter;