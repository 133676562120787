import React from "react";

interface IProps {
    width: number
    height: number
    fill: string
}

export const ChatIcon: React.FC<IProps> = ({width, height, fill}) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         version="1.1"
         id="svg879"
         width={`${width}px`}
         height={`${height}px`}
         fill={fill}
         viewBox="0 0 682.66669 682.66669">
        <defs id="defs883">
            <clipPath clipPathUnits="userSpaceOnUse" id="clipPath893">
                <path d="M 0,512 H 512 V 0 H 0 Z" id="path891"/>
            </clipPath>
        </defs>
        <mask id="custom">
            <rect id="bg" x="0" y="0" width="100%" height="100%" fill="white"/>
            <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
                <path transform="matrix(1,0,0,1,281,256)"
                      d="m 0,0 c 0,-13.807 -11.193,-25 -25,-25 -13.807,0 -25,11.193 -25,25 0,13.807 11.193,25 25,25 C -11.193,25 0,13.807 0,0"
                       id="path901"/>
                <path transform="matrix(1,0,0,1,381,256)"
                      d="m 0,0 c 0,-13.807 -11.193,-25 -25,-25 -13.807,0 -25,11.193 -25,25 0,13.807 11.193,25 25,25 C -11.193,25 0,13.807 0,0"
                      id="path905"/>
                <path transform="matrix(1,0,0,1,181,256)"
                      d="m 0,0 c 0,-13.807 -11.193,-25 -25,-25 -13.807,0 -25,11.193 -25,25 0,13.807 11.193,25 25,25 C -11.193,25 0,13.807 0,0"
                       id="path909"/>
            </g>
        </mask>
        <g mask="url(#custom)">
            <g id="g885" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
                <g id="g887">
                    <g id="g889" clipPath="url(#clipPath893)">
                        <g id="g895" transform="translate(256,492)">
                            <path
                                d="m 0,0 c -130.339,0 -236,-105.661 -236,-236 0,-45.885 13.109,-88.702 35.766,-124.937 L -236,-472 l 111.063,35.766 C -88.702,-458.891 -45.885,-472 0,-472 c 130.339,0 236,105.661 236,236 C 236,-105.661 130.339,0 0,0 Z"

                                id="path897"/>
                        </g>
                        <g id="g899" transform="translate(281,256)">
                            <path
                                d="m 0,0 c 0,-13.807 -11.193,-25 -25,-25 -13.807,0 -25,11.193 -25,25 0,13.807 11.193,25 25,25 C -11.193,25 0,13.807 0,0"
                                />
                        </g>
                        <g id="g903" transform="translate(381,256)">
                            <path
                                d="m 0,0 c 0,-13.807 -11.193,-25 -25,-25 -13.807,0 -25,11.193 -25,25 0,13.807 11.193,25 25,25 C -11.193,25 0,13.807 0,0"
                                />
                        </g>
                        <g id="g907" transform="translate(181,256)">
                            <path
                                d="m 0,0 c 0,-13.807 -11.193,-25 -25,-25 -13.807,0 -25,11.193 -25,25 0,13.807 11.193,25 25,25 C -11.193,25 0,13.807 0,0"
                                />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>


)