import * as React from 'react';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import {visuallyHidden} from '@mui/utils';
import {useRecoilState, useSetRecoilState} from "recoil";
import {memberListState, userFilterState} from "@pages/userManage/atoms";
import {
    getMemberItemTypeKor,
    getMemberStateKor, getQuestionStateKor,
    getStringDateTime,
    isIterableArray
} from "@/presentation/helpers/utility";
import {Link, useNavigate} from "react-router-dom";
import {presentationError} from "@/presentation/errors/presentationError";
import ReactPaginate from "react-paginate";
import {notificationAlert} from "@/presentation/helpers/alert";
import {deleteMultiMember} from "@/domain/repository/MemberRepository";
import Swal from "sweetalert2";
import {questionFilterState, questionListState} from "@pages/question/atoms";
import {deleteMultiQuestion} from "@/domain/repository/QuestionRepository";
import {locationFilterState, locationListState} from "@pages/location/atoms";
import {deleteMultiLocation} from "@/domain/repository/LocationRepository";


type Order = 'ASC' | 'DESC';


interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
    sortId?: string
}

const headCells: readonly HeadCell[] = [
    {
        id: 'index',
        numeric: false,
        disablePadding: true,
        label: '번호',
    },
    {
        id: 'bcode',
        numeric: true,
        disablePadding: false,
        label: '지역코드',
    },
    {
        id: 'siDo',
        numeric: true,
        disablePadding: false,
        label: '주소 이름',
    },
    {
        id: 'createdDate',
        numeric: true,
        disablePadding: false,
        label: '등록일',
        sortId: 'CREATED'
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
        props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.sortId ? order === 'ASC' ? 'asc' : 'desc' : false}
                    >
                        {headCell.sortId ?
                            <TableSortLabel
                                active={orderBy === headCell.sortId}
                                direction={orderBy === headCell.sortId ? order === 'ASC' ? 'asc' : 'desc' : 'asc'}
                                onClick={createSortHandler(headCell.sortId)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                            :
                            <>
                                {headCell.label}
                            </>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function LocationListTable() {
    const navigator = useNavigate();
    const [order, setOrder] = React.useState<Order>('ASC');
    const [orderBy, setOrderBy] = React.useState<string>('memberId');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [dense, setDense] = React.useState(false);
    const [membersState, setMemberState] = useRecoilState(locationListState);
    const setFilterState = useSetRecoilState(locationFilterState);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'ASC';
        setFilterState(old => ({
            ...old,
            sortName: property,
            sortType: isAsc ? 'DESC' : 'ASC'
        }));
        setOrderBy(property);
        setOrder(isAsc ? 'DESC' : 'ASC')
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = membersState.addresses.map((n) => String(n.bcode));
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleClickPage = (page: { selected: number }) => {
        setFilterState(old => ({
            ...old,
            page: page.selected + 1
        }));
    }

    const handleDeleteSelectMember = async () => {
        try {
            if (!isIterableArray(selected)) {
                notificationAlert('알림', '삭제하실 항목을 선택하여 주세요.');
                return;
            }
            Swal.fire({
                title: '정말 회원를 삭제 하시겠습니까?',
                confirmButtonText: '삭제',
                showCancelButton: true,
                cancelButtonText: '취소'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await deleteMultiLocation(selected);

                    if (result.success) {
                        let newMembers = membersState.addresses.filter(m => !selected.includes(String(m.bcode)));
                        setMemberState(old => ({...old, addresses: [...newMembers]}));
                        setSelected([]);
                        notificationAlert('알림', '삭제되었습니다.');

                    }

                }
            })

        } catch (err) {
            presentationError({
                error: err,
                type: '회원 삭제',
                alert: true
            });
        }
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1;


    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                <TableContainer>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={membersState.addresses.length}
                        />
                        <TableBody>
                            {membersState.addresses
                                .map((row, index) => {
                                    const isItemSelected = isSelected(String(row.bcode));
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, String(row.bcode))}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.bcode}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align={'center'}
                                            >
                                                {(10 * (membersState.nowPage - 1)) + (index + 1)}
                                            </TableCell>
                                            <TableCell align="center">{row.bcode}</TableCell>
                                            <TableCell
                                                align="center">
                                                <Link to={`/location/${row.bcode}`}>
                                                    {`${row.siDo} ${row.siGunGu} ${row.eupMyeonDong}`}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center">{getStringDateTime(row.createdDate)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div className={'paginate_wrapper'}>
                <ReactPaginate
                    className={'paginate'}
                    breakLabel="..."
                    nextLabel="❯"
                    onPageChange={handleClickPage}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(membersState.totalCnt / 10)}
                    previousLabel="❮"
                />
            </div>
            <div className={'bottom_btn'}>
                <button onClick={handleDeleteSelectMember}>선택항목 삭제</button>
                <button onClick={() => navigator('/location/create')}>지역 추가</button>
            </div>
        </Box>
    );
}
