import React, {useState, KeyboardEvent, useEffect} from 'react';
import Input from './Input';
import LoaderButton from "@/presentation/components/button/LoaderButton";
import {useRecoilState, useResetRecoilState} from "recoil";
import {presentationError} from "@/presentation/errors/presentationError";
import {loginFormState} from "@/presentation/pages/auth/login/atoms";
import {generateRandomCode} from "@/presentation/helpers/auth";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import classNames from "classnames";
import {login} from "@/domain/repository/AuthRepository";
import {notificationAlert} from "@/presentation/helpers/alert";

const LoginPage: React.FC = () => {
    const [loginForm, setLoginForm] = useRecoilState(loginFormState);
    const [loader, setLoader] = useState<boolean>(false);
    const [cookies, setCookie, removeCookie] = useCookies(["rememberUserId"]);
    const [isRemember, setIsRemember] = useState(false);
    const navigator = useNavigate();

    const resetFormData = useResetRecoilState(loginFormState);

    useEffect(() => {
        resetFormData();
    }, [])


    const handleLogin = async () => {
        try {
            setLoader(true);
            const result = await login(loginForm);

            if (result.success) {
                localStorage.setItem('propt_AATh', generateRandomCode(16));
                setCookie("rememberUserId", loginForm.phoneNumber);

                navigator('/');
            } else {
                notificationAlert('오류', '일치하는 회원이 없습니다.');
            }

            setLoader(false);
        } catch (err) {
            presentationError({
                error: err,
                type: '로그인',
                alert: true
            });
            setLoader(false);
        }
    }

    const [userid, setUserid] = useState("");

    useEffect(() => {
        if (cookies.rememberUserId !== undefined) {
            setUserid(cookies.rememberUserId);
            setLoginForm(old => ({...old, phoneNumber: cookies.rememberUserId}));
            setIsRemember(true);
        }
    }, []);

    const handleOnChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setIsRemember(e.target.checked);
        if (!e.target.checked) {
            removeCookie("rememberUserId");
        }
    };

    const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="login_page_wrapper">
            <div className="login_box">
                <h1 style={{marginBottom: '2rem'}}>관리자 로그인</h1>
                {/*<div className="main_logo">*/}
                {/*    <img src={mainLogo} alt={'main_logo'}/>*/}
                {/*</div>*/}
                <Input onKeyPress={handleOnKeyPress} maxLength={50} type="text" name="phoneNumber" placeholder="아이디"/>
                <Input onKeyPress={handleOnKeyPress} maxLength={255} type="password" name="password"
                       placeholder="비밀번호"/>
                <LoaderButton onClick={handleLogin} loader={loader} type="button">로그인</LoaderButton>
                <div className={'to_register'}>
                    <div className={"form-group"}>
                        <div className={'form_check'}>
                            <label className={classNames({
                                'check_label_wrapper': true,
                                'checked': isRemember
                            })}>
                                <input
                                    type={'checkbox'}
                                    checked={isRemember}
                                    onChange={handleOnChange}/>
                                <span className="checkmark"/>
                                <span>아이디 저장</span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LoginPage;
