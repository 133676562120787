import {ISelectItem} from "@components/select/SelectBase";


export const filterStatusOptions: ISelectItem[] = [
    {
        value: 'ALL',
        txt: '전체'
    },
    {
        value: 'DEFAULT',
        txt: '정상'
    },
    {
        value: 'SANCTION',
        txt: '제재'
    },
    {
        value: 'LEAVE',
        txt: '탈퇴'
    },
];

export const filterSearchTypeOptions: ISelectItem[] = [
    {
        value: 'ALL',
        txt: '전체'
    },
    {
        value: 'NAME',
        txt: '이름'
    },
    {
        value: 'NICKNAME',
        txt: '닉네임'
    },
    {
        value: 'PHONE',
        txt: 'ID(휴대폰번호)'
    },
];

export const filterSearchItemTypeOptions: ISelectItem[] = [
    {
        value: 'ALL',
        txt: '전체'
    },
    {
        value: 'LOCATION',
        txt: '매물위치'
    },
    {
        value: 'DESCRIPTION',
        txt: '소개글'
    }
];

export const filterTradeTypeOptions: ISelectItem[] = [
    {
        value: 'ALL',
        txt: '전체'
    },
    {
        value: 'SALE',
        txt: '매매'
    },
    {
        value: 'JEONSE',
        txt: '전세'
    },
    {
        value: 'MONTHLY',
        txt: '월세'
    },
];

export const tradeTypeOptions: ISelectItem[] = [
    {
        value: 'SALE',
        txt: '매매'
    },
    {
        value: 'JEONSE',
        txt: '전세'
    },
    {
        value: 'MONTHLY',
        txt: '월세'
    },
]

export const filterBoardTypeOptions: ISelectItem[] = [
    {
        value: 'ALL',
        txt: '전체'
    },
    {
        value: 'WAIT',
        txt: '등록대기'
    },
    {
        value: 'SALE',
        txt: '판매중'
    },
    {
        value: 'WAITDEPOSIT',
        txt: '입금대기중'
    },
    {
        value: 'SOLDOUT',
        txt: '판매완료'
    }
];

export const boardTypeOptions: ISelectItem[] = [
    {
        value: 'WAIT',
        txt: '등록대기'
    },
    {
        value: 'SALE',
        txt: '판매중'
    },
    {
        value: 'WAITDEPOSIT',
        txt: '입금대기중'
    },
    {
        value: 'SOLDOUT',
        txt: '판매완료'
    }
]

export const userRankOptions: ISelectItem[] = [
    {
        value: '일반회원',
        txt: '일반회원'
    },
    {
        value: '관리자',
        txt: '관리자'
    }
];

export const useStatusOptions: ISelectItem[] = [
    {
        value: 'DEFAULT',
        txt: '정상'
    },
    {
        value: 'SANCTION',
        txt: '제재'
    },
    {
        value: 'LEAVE',
        txt: '탈퇴'
    }
];

export const questionStatusTypeOptions: ISelectItem[] = [
    {
        value: 'ALL',
        txt: '전체'
    },
    {
        value: 'WAIT',
        txt: '처리대기'
    },
    {
        value: 'COMPLETE',
        txt: '완료'
    }
];


export const filterQsSearchTypeOptions: ISelectItem[] = [
    {
        value: 'ALL',
        txt: '전체'
    },
    {
        value: 'PHONE',
        txt: '연락처'
    },
    {
        value: 'EMAIL',
        txt: '이메일'
    },
    {
        value: 'CONTENT',
        txt: '문의 내용'
    },
];


export const filterSiDoOptions: ISelectItem[] = [
    {
        "value": "",
        "txt": "시/도"
    },
    {
        "value": "서울특별시",
        "txt": "서울특별시"
    },
    {
        "value": "인천광역시",
        "txt": "인천광역시"
    },
    {
        "value": "대전광역시",
        "txt": "대전광역시"
    },
    {
        "value": "광주광역시",
        "txt": "광주광역시"
    },
    {
        "value": "대구광역시",
        "txt": "대구광역시"
    },
    {
        "value": "울산광역시",
        "txt": "울산광역시"
    },
    {
        "value": "부산광역시",
        "txt": "부산광역시"
    },
    {
        "value": "경기도",
        "txt": "경기도"
    },
    {
        "value": "강원도",
        "txt": "강원도"
    },
    {
        "value": "충청북도",
        "txt": "충청북도"
    },
    {
        "value": "충청남도",
        "txt": "충청남도"
    },
    {
        "value": "전라북도",
        "txt": "전라북도"
    },
    {
        "value": "전라남도",
        "txt": "전라남도"
    },
    {
        "value": "경상북도",
        "txt": "경상북도"
    },
    {
        "value": "경상남도",
        "txt": "경상남도"
    },
    {
        "value": "제주도",
        "txt": "제주도"
    }
]

const area1 = ["강남구", "강동구", "강북구", "강서구", "관악구", "광진구", "구로구", "금천구", "노원구", "도봉구", "동대문구", "동작구", "마포구", "서대문구", "서초구", "성동구", "성북구", "송파구", "양천구", "영등포구", "용산구", "은평구", "종로구", "중구", "중랑구"];
const area2 = ["계양구", "남구", "남동구", "동구", "부평구", "서구", "연수구", "중구", "강화군", "옹진군"];
const area3 = ["대덕구", "동구", "서구", "유성구", "중구"];
const area4 = ["광산구", "남구", "동구", "북구", "서구"];
const area5 = ["남구", "달서구", "동구", "북구", "서구", "수성구", "중구", "달성군"];
const area6 = ["남구", "동구", "북구", "중구", "울주군"];
const area7 = ["강서구", "금정구", "남구", "동구", "동래구", "부산진구", "북구", "사상구", "사하구", "서구", "수영구", "연제구", "영도구", "중구", "해운대구", "기장군"];
const area8 = ["고양시", "과천시", "광명시", "광주시", "구리시", "군포시", "김포시", "남양주시", "동두천시", "부천시", "성남시", "수원시", "시흥시", "안산시", "안성시", "안양시", "양주시", "오산시", "용인시", "의왕시", "의정부시", "이천시", "파주시", "평택시", "포천시", "하남시", "화성시", "가평군", "양평군", "여주군", "연천군"];
const area9 = ["강릉시", "동해시", "삼척시", "속초시", "원주시", "춘천시", "태백시", "고성군", "양구군", "양양군", "영월군", "인제군", "정선군", "철원군", "평창군", "홍천군", "화천군", "횡성군"];
const area10 = ["제천시", "청주시", "충주시", "괴산군", "단양군", "보은군", "영동군", "옥천군", "음성군", "증평군", "진천군", "청원군"];
const area11 = ["계룡시", "공주시", "논산시", "보령시", "서산시", "아산시", "천안시", "금산군", "당진군", "부여군", "서천군", "연기군", "예산군", "청양군", "태안군", "홍성군"];
const area12 = ["군산시", "김제시", "남원시", "익산시", "전주시", "정읍시", "고창군", "무주군", "부안군", "순창군", "완주군", "임실군", "장수군", "진안군"];
const area13 = ["광양시", "나주시", "목포시", "순천시", "여수시", "강진군", "고흥군", "곡성군", "구례군", "담양군", "무안군", "보성군", "신안군", "영광군", "영암군", "완도군", "장성군", "장흥군", "진도군", "함평군", "해남군", "화순군"];
const area14 = ["경산시", "경주시", "구미시", "김천시", "문경시", "상주시", "안동시", "영주시", "영천시", "포항시", "고령군", "군위군", "봉화군", "성주군", "영덕군", "영양군", "예천군", "울릉군", "울진군", "의성군", "청도군", "청송군", "칠곡군"];
const area15 = ["거제시", "김해시", "마산시", "밀양시", "사천시", "양산시", "진주시", "진해시", "창원시", "통영시", "거창군", "고성군", "남해군", "산청군", "의령군", "창녕군", "하동군", "함안군", "함양군", "합천군"];
const area16 = ["서귀포시", "제주시", "남제주군", "북제주군"];

export const getSiGunGuOptions = (siDo: string | null | undefined) => {
    if (!siDo) return [];
    switch (siDo) {
        case '서울특별시':
            return area1.map(ar => ({value: ar, txt: ar}));
        case '인천광역시':
            return area2.map(ar => ({value: ar, txt: ar}));
        case '대전광역시':
            return area3.map(ar => ({value: ar, txt: ar}));
        case '광주광역시':
            return area4.map(ar => ({value: ar, txt: ar}));
        case '대구광역시':
            return area5.map(ar => ({value: ar, txt: ar}));
        case '울산광역시':
            return area6.map(ar => ({value: ar, txt: ar}));
        case '부산광역시':
            return area7.map(ar => ({value: ar, txt: ar}));
        case '경기도':
            return area8.map(ar => ({value: ar, txt: ar}));
        case '강원도':
            return area9.map(ar => ({value: ar, txt: ar}));
        case '충청북도':
            return area10.map(ar => ({value: ar, txt: ar}));
        case '충청남도':
            return area11.map(ar => ({value: ar, txt: ar}));
        case '전라북도':
            return area12.map(ar => ({value: ar, txt: ar}));
        case '전라남도':
            return area13.map(ar => ({value: ar, txt: ar}));
        case '경상북도':
            return area14.map(ar => ({value: ar, txt: ar}));
        case '경상남도':
            return area15.map(ar => ({value: ar, txt: ar}));
        case '제주도':
            return area16.map(ar => ({value: ar, txt: ar}));

        default:
            return [];
    }
}