import {atom} from 'recoil'
import {LoginRequest} from "@/domain/model/request/loginRequest";
import {v1} from 'uuid';


export const loginFormState = atom<LoginRequest>({
    key: `loginFormState/${v1()}`,
    default: {
        phoneNumber: '',
        password: ''
    }
})

