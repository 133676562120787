import {LocalStorageAdapter} from "./local-storage-adapter";
import {HttpClient, HttpRequest, HttpResponse} from "./http-client";
import {AxiosHttpClient} from "./axios-http-client";

export class AuthorizeHttpClient implements HttpClient {

  async request(data: HttpRequest): Promise<HttpResponse> {
    const storage = new LocalStorageAdapter();
    const token = storage.get('token');
    if (token?.access) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: `Bearer ${token.access}`,
        }),
      });
    }
    const httpClient = new AxiosHttpClient();
    const httpResponse = await httpClient.request(data);
    return httpResponse;
  }
}
