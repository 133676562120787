import * as React from 'react';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import {visuallyHidden} from '@mui/utils';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {memberItemFilterState, memberItemListState, memberListState, userFilterState} from "@pages/userManage/atoms";
import {
    getMemberItemStateKor,
    getMemberItemTypeKor,
    getMemberStateKor,
    getStringDate,
    getStringDateTime,
    isIterableArray
} from "@/presentation/helpers/utility";
import {Link, useNavigate} from "react-router-dom";
import {presentationError} from "@/presentation/errors/presentationError";
import ReactPaginate from "react-paginate";
import {notificationAlert} from "@/presentation/helpers/alert";
import {deleteMember, deleteMultiMember} from "@/domain/repository/MemberRepository";
import Swal from "sweetalert2";


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'ASC' | 'DESC';


interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
    sortId?: string
}

const headCells: readonly HeadCell[] = [
    {
        id: 'itemType',
        numeric: true,
        disablePadding: false,
        label: '거래종류',
        sortId: 'TYPE'
    },
    {
        id: 'itemState',
        numeric: true,
        disablePadding: false,
        label: '게시글상태',
        sortId: 'STATE'
    },
    {
        id: 'addressInfo',
        numeric: true,
        disablePadding: false,
        label: '매물위치',
    },
    {
        id: 'description',
        numeric: true,
        disablePadding: false,
        label: '소개글',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: '매매가/전세/보증금',
    },
    {
        id: 'monthlyRent',
        numeric: true,
        disablePadding: false,
        label: '월세',
    },
    {
        id: 'keyMoney',
        numeric: true,
        disablePadding: false,
        label: '권리금',
    },
    {
        id: 'maintenanceFee',
        numeric: true,
        disablePadding: false,
        label: '관리비',
    },
    {
        id: 'createdDate',
        numeric: true,
        disablePadding: false,
        label: '게시일',
        sortId: 'CREATED'
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
        props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.sortId ? order === 'ASC' ? 'asc' : 'desc' : false}
                    >
                        {headCell.sortId ?
                            <TableSortLabel
                                active={orderBy === headCell.sortId}
                                direction={orderBy === headCell.sortId ? order === 'ASC' ? 'asc' : 'desc' : 'asc'}
                                onClick={createSortHandler(headCell.sortId)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel> :
                            <>
                                {headCell.label}
                            </>

                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function SellHistoryTable() {
    const navigator = useNavigate();
    const [order, setOrder] = React.useState<Order>('ASC');
    const [orderBy, setOrderBy] = React.useState<string>('memberId');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [dense, setDense] = React.useState(false);
    const [membersState, setMemberState] = useRecoilState(memberItemListState);
    const setFilterState = useSetRecoilState(memberItemFilterState);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'ASC';
        setFilterState(old => ({
            ...old,
            sortName: property,
            sortType: isAsc ? 'DESC' : 'ASC'
        }));
        setOrderBy(property);
        setOrder(isAsc ? 'DESC' : 'ASC')
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = membersState.items.map((n) => String(n.itemId));
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleClickPage = (page: { selected: number }) => {
        setFilterState(old => ({
            ...old,
            page: page.selected + 1
        }));
    }


    const isSelected = (name: string) => selected.indexOf(name) !== -1;


    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                <TableContainer>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={membersState.items.length}
                        />
                        <TableBody>
                            {membersState.items
                                .map((row, index) => {
                                    const isItemSelected = isSelected(String(row.itemId));
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, String(row.itemId))}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.itemId}
                                            selected={isItemSelected}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align={'center'}
                                            >
                                                {getMemberItemTypeKor(row.itemType)}
                                            </TableCell>
                                            <TableCell align="center">{getMemberItemStateKor(row.itemState)}</TableCell>
                                            <TableCell align="center">{row.addressInfo.siDo}</TableCell>
                                            <TableCell align="center">{row.description}</TableCell>
                                            <TableCell align="center">{row.price}</TableCell>
                                            <TableCell align="center">
                                                {row.monthlyRent}
                                            </TableCell>
                                            <TableCell align="center">{row.keyMoney}</TableCell>
                                            <TableCell align="center">{row.maintenanceFee}</TableCell>
                                            <TableCell align={"center"}>{getStringDateTime(row.createdDate)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div className={'paginate_wrapper'}>
                <ReactPaginate
                    className={'paginate'}
                    breakLabel="..."
                    nextLabel="❯"
                    onPageChange={handleClickPage}
                    pageRangeDisplayed={5}
                    pageCount={membersState.items.length / 10}
                    previousLabel="❮"
                    // renderOnZeroPageCount={null}
                />
            </div>
        </Box>
    );
}
