import React, {useEffect, useState} from "react";
import {
    filterQsSearchTypeOptions, filterSiDoOptions, getSiGunGuOptions,
} from "@/presentation/data/options";
import {Col, Row} from "react-bootstrap";
import Select from "@pages/location/form/filter/Select";
import Input from "@pages/location/form/filter/Input";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {locationFilterState, searchFilterState} from "@pages/location/atoms";
import {getSido} from "@/domain/repository/LocationRepository";
import {presentationError} from "@/presentation/errors/presentationError";
import {isIterableArray} from "@/presentation/helpers/utility";
import {ISelectItem} from "@components/select/SelectBase";


const TableFilter: React.FC = () => {
    const resetFilter = useResetRecoilState(locationFilterState);
    const resetSearchFilter = useResetRecoilState(searchFilterState);
    const [locationFilter, setFilter] = useRecoilState(locationFilterState);
    const [searchFilter, setSearchFilter] = useRecoilState(searchFilterState);
    const [siDoOptions, setSiDoOptions] = useState<ISelectItem[]>([]);
    const [siGunGuOptions, setSiGunGuOptions] = useState<ISelectItem[]>([]);

    useEffect(() => {
        handleReset();
    }, [])

    const handleSearch = () => {
        setFilter(old => ({
            ...old,
            ...searchFilter
        }))
    }

    useEffect(() => {
        setSearchFilter(old => ({...old, siGunGu: ''}));
        setFilter(old => ({...old, siGunGu: ''}));
    }, [searchFilter.siDo])

    const handleReset = () => {
        resetFilter();
        resetSearchFilter();
    }
    useEffect(() => {
        handleReset();
        getSidoOptions();
    }, [])


    const getSidoOptions = async () => {
        try {
            const result = await getSido('');
            if (result.response)
                if (isIterableArray(result.response)) {
                    setSiDoOptions([
                            {
                                "value": "",
                                "txt": "시/도"
                            },
                            ...result.response.map(r => ({
                                "value": r,
                                "txt": r
                            }))
                        ]
                    )
                }
        } catch (err) {
            presentationError({
                error: err,
                type: '시/도 가져오기',
                alert: true
            });
        }
    }

    useEffect(() => {
        getSiGunGuOptions();
    }, [searchFilter.siDo])

    const getSiGunGuOptions = async () => {
        try {
            if (!searchFilter.siDo) return;
            const result = await getSido(searchFilter.siDo);
            if (result.response)
                if (isIterableArray(result.response)) {
                    setSiGunGuOptions(result.response.map(r => ({
                            "value": r,
                            "txt": r
                        }))
                    )
                }
        } catch (err) {
            presentationError({
                error: err,
                type: '시/군/구 가져오기',
                alert: true
            });
        }
    }


    return (
        <div className={'table_filter'}>
            <ul className={'filter_options'}>
                <li className={'w-75'}>
                    <div className={'option_name'}>지역선택</div>
                    <Row className={'flex-grow-1'}>
                        <Col xs={3} style={{paddingRight: 0}}>
                            <Select placeholder={'시/도'} name={'siDo'} options={siDoOptions}/>
                        </Col>
                        <Col xs={3} style={{paddingRight: 0}}>
                            <Select placeholder={'시/군/구'} name={'siGunGu'}
                                    options={searchFilter.siDo ? [{
                                        "value": "",
                                        "txt": "시/군/구"
                                    }, ...siGunGuOptions] : [{
                                        "value": "",
                                        "txt": "시/군/구"
                                    },]}/>
                        </Col>
                        <Col xs={6} style={{paddingLeft: '12px'}}>
                            <Input type={'text'} name={'word'} placeholder={'검색어'} maxLength={255}/>
                        </Col>
                    </Row>
                </li>
            </ul>
            <div className={'filter_btns'}>
                <button id={'search_btn'} onClick={handleSearch}>검색</button>
                <button id={'init_btn'} onClick={handleReset}>초기화</button>
            </div>
        </div>
    )
}

export default TableFilter;