import React, {useEffect} from "react";
import Select from "@pages/question/form/filter/Select";
import {
    filterQsSearchTypeOptions, questionStatusTypeOptions
} from "@/presentation/data/options";
import Input from "@pages/question/form/filter/Input";
import {Col, Row} from "react-bootstrap";
import DatePicker from "@pages/question/form/filter/DatePicker";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {questionFilterState, searchFilterState} from "@pages/question/atoms";


const TableFilter: React.FC = () => {

    const resetFilter = useResetRecoilState(questionFilterState);
    const resetSearchFilter = useResetRecoilState(searchFilterState);
    const setFilter = useSetRecoilState(questionFilterState);
    const searchFilter = useRecoilValue(searchFilterState);

    const handleSearch = () => {
        setFilter(old => ({
            ...old,
            ...searchFilter
        }))
    }

    const handleReset = () => {
        resetFilter();
        resetSearchFilter();
    }

    useEffect(() => {
        handleReset();
    }, [])


    return (
        <div className={'table_filter'}>
            <ul className={'filter_options'}>
                <li style={{width: '20%'}}>
                    <div className={'option_name'}>상태</div>
                    <Select name={'state'} options={questionStatusTypeOptions}/>
                </li>
                <li style={{width: '35%'}}>
                    <div className={'option_name'}>검색어</div>
                    <Row className={'flex-grow-1'}>
                        <Col xs={4} style={{paddingRight: 0}}>
                            <Select name={'questionSearchType'} options={filterQsSearchTypeOptions}/>
                        </Col>
                        <Col xs={8} style={{paddingLeft: 0}}>
                            <Input type={'text'} name={'word'} placeholder={''} maxLength={255}/>
                        </Col>
                    </Row>
                </li>
                <li style={{width: '35%'}}>
                    <div className={'option_name'}>게시일</div>
                    <Row className={'flex-grow-1'}>
                        <Col xs={5} style={{paddingRight: 0}}>
                            <DatePicker name={'startCreatedDate'}/>
                        </Col>
                        <Col xs={2}>
                            <div className={'time_flow'}>
                                ~
                            </div>
                        </Col>
                        <Col xs={5} style={{paddingLeft: 0}}>
                            <DatePicker name={'endCreatedDate'}/>
                        </Col>
                    </Row>
                </li>
            </ul>
            <div className={'filter_btns'}>
                <button id={'search_btn'} onClick={handleSearch}>검색</button>
                <button id={'init_btn'} onClick={handleReset}>초기화</button>
            </div>
        </div>
    )
}

export default TableFilter;