import React, {ReactNode} from "react";
import CircularProgress from '@mui/material/CircularProgress';

type IProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    children: ReactNode
    loader: boolean
}


const LoaderButton: React.FC<IProps> = ({children, loader, ...props}) => {


    return (
        <button id={'loader_btn'} {...props}>
            {loader ?
                <CircularProgress className={'btn_spinner'}/>
                :
                <>
                    {children}
                </>
            }
        </button>
    )
}

export default LoaderButton;