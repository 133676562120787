import React, {useEffect} from "react";
import {BasePage} from "@components/page/BasePage";
import TableFilter from "@pages/question/TableFilter";
import QuestionListTable from "@pages/question/QuestionListTable";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberListState, userFilterState} from "@pages/userManage/atoms";
import {getMembers} from "@/domain/repository/MemberRepository";
import {presentationError} from "@/presentation/errors/presentationError";
import {questionFilterState, questionListState} from "@pages/question/atoms";
import {getQuestions} from "@/domain/repository/QuestionRepository";


const QuestionManagePage: React.FC = () => {

    const setQuestions = useSetRecoilState(questionListState);
    const filterState = useRecoilValue(questionFilterState);
    useEffect(() => {
        getQuestionList();
    }, [filterState])

    const getQuestionList = async () => {
        try {
            const result = await getQuestions(filterState);

            if (result.success && result.response) {
                setQuestions(result.response);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '유저 가져오기',
                alert: true
            });
        }
    }

    return (
        <BasePage title={'문의 관리'}>
            <div className={'question_manage_page'}>
                <TableFilter/>
                <QuestionListTable/>
            </div>
        </BasePage>
    )
}


export default QuestionManagePage;