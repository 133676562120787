import dayjs from "dayjs";

export const isIterableArray = (array: any[] | FileList) => Array.isArray(array) && !!array.length;

export const isEmpty = (string: string) => !(!!string.length)

export const getMemberStateKor = (state: string): string => {
    switch (state) {
        case 'DEFAULT':
            return '정상';
        case 'SANCTION':
            return '제재'

        case 'LEAVE':
            return '탈퇴'
        default:
            return '';
    }
}

export const getMemberItemTypeKor = (type: string): string => {
    switch (type) {
        case 'SALE':
            return '매매';
        case 'JEONSE':
            return '전세'

        case 'MONTHLY':
            return '월세'
        default:
            return '';
    }
}

export const getQuestionStateKor = (type: string): string => {
    switch (type) {
        case 'WAIT':
            return '처리대기';
        case 'COMPLETE':
            return '완료'

        default:
            return '';
    }
}

export const getMemberItemStateKor = (state: string): string => {
    switch (state) {
        case 'WAIT':
            return '등록대기';
        case 'SALE':
            return '판매중';

        case 'WAITDEPOSIT':
            return '입금대기중';

        case 'SOLDOUT':
            return '판매완료';


        default:
            return '';
    }
}

export const getStringDate = (date: string | Date): string => {

    if (!date) return '';
    if (date === '0000-00-00') return '';

    const result = dayjs(date).format('YYYY-MM-DD')
    if (result === 'Invalid Date') return ''
    return result;
}

export const getStringDateTime = (date: string | Date): string => {

    if (!date) return '';
    if (date === '0000-00-00') return '';

    const result = dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    if (result === 'Invalid Date') return ''
    return result;
}

export const getPathHistory = (path: string, itemNo: string | undefined): string => {
    switch (path) {
        case '/':
            return '회원관리';
        case `/user/${itemNo}`:
            return '회원관리 - 회원상세';
        case `/user/${itemNo}/modify`:
            return '회원관리 - 회원정보수정';
        case "/user/add":
            return '회원관리 - 회원추가';

        case "/salesPost":
            return '판매글 관리';
        case "/salesPost/create":
            return '판매글 관리 - 판매글 등록';
        case `/salesPost/${itemNo}`:
            return '판매글 관리 - 판매글 상세';
        case `/salesPost/${itemNo}/modify`:
            return '판매글 관리 - 판매글 수정';

        case "/question":
            return '문의 관리';
        case `/question/${itemNo}`:
            return '문의 관리 - 문의 상세';

        case "/location":
            return '지역 관리';
        case "/location/create":
            return '지역 관리 - 지역 추가';
        case `/location/${itemNo}`:
            return '지역 관리 - 지역 상세';
        case `/location/${itemNo}/modify`:
            return '지역 관리 - 지역 수정';

        default:
            return '';
    }
}