import React, {useEffect} from "react";
import {BasePage} from "@components/page/BasePage";
import {Col, Row} from "react-bootstrap";
import Input from "@pages/userManage/form/create/Input";
import Select from "@pages/userManage/form/create/Select";
import {userRankOptions} from "@/presentation/data/options";
import {useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {createMemberFormState} from "@pages/userManage/atoms";
import {presentationError} from "@/presentation/errors/presentationError";
import {createMember} from "@/domain/repository/MemberRepository";
import {notificationAlert} from "@/presentation/helpers/alert";
import {isIterableArray} from "@/presentation/helpers/utility";


const UserAddPage: React.FC = () => {

    const navigator = useNavigate();
    const [formData, setFormData] = useRecoilState(createMemberFormState);
    const resetFormData = useResetRecoilState(createMemberFormState);

    useEffect(() => {
        resetFormData();
    },[])

    const handleCreateMember = async () => {
        try {
            const result = await createMember(formData);

            if (result.success) {
                notificationAlert('알림', '회원이 추가되었습니다.');
                navigator('/')
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '회원 생성',
                alert: true
            });
        }
    }

    const uploadFile = (file: FileList | null) => {
        if (file){
            setFormData(old => ({...old, profileImage: file[0]}))
        }
    }


    return (
        <BasePage title={'회원 추가'}>
            <div className={'user_add_page'}>
                <ul className={'info_list'}>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 이름
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'name'} placeholder={'이름'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * ID(휴대폰번호)
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'phoneNumber'} placeholder={'- 없이 입력하세요.'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 닉네임
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'nickname'} placeholder={'닉네임'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                프로필 사진
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <input type={'file'}
                                       accept="image/*"
                                       multiple={false}
                                       onChange={event => uploadFile(event.target.files)}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 비밀번호
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'password'} name={'password'} placeholder={'비밀번호'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 비밀번호 확인
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'password'} name={'passwordConfirm'} placeholder={'비밀번호 확인'}
                                       maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                회원등급
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Select name={'grade'} options={userRankOptions}/>
                            </Col>
                        </Row>
                    </li>
                </ul>
                <div className={'center_bottom_btn'}>
                    <button onClick={handleCreateMember}>등록</button>
                    <button onClick={() => navigator(-1)}>취소</button>
                </div>
            </div>
        </BasePage>
    )
}


export default UserAddPage;