import React, {ReactNode} from "react";
import MainLayout from "@components/layout/MainLayout";
import {HomeIcon} from "@components/icon/HomeIcon";
import {useLocation, useParams} from "react-router-dom";
import {getPathHistory} from "@/presentation/helpers/utility";

interface IProps {
    title: string
    children: ReactNode
}

export const BasePage: React.FC<IProps> = ({title, children}) => {
    const location = useLocation();
    const {no} = useParams<{ no: string }>();
    return (
        <MainLayout>
            <div className={'history_bar'}>
                <HomeIcon width={20} height={20} fill={'#5F5F5F'}/>
                <span className={'history_arrow'}>❯</span>
                {getPathHistory(location.pathname, no)}
            </div>
            <div className={'page_wrapper'}>
                <div className={'page_container'}>
                    <div className={'page_title'}>
                        <h2>{title}</h2>
                    </div>
                    <div className={'page_content'}>
                        {children}
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}