import React, {useEffect} from "react";
import {BasePage} from "@components/page/BasePage";
import {Col, Row} from "react-bootstrap";
import Input from "@pages/location/form/modify/Input";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState, useResetRecoilState} from "recoil";
import {locationUpdateFormState} from "@pages/location/atoms";
import {getLocationDetail, updateLocation} from "@/domain/repository/LocationRepository";
import {presentationError} from "@/presentation/errors/presentationError";
import {notificationAlert} from "@/presentation/helpers/alert";


const LocationModifyPage: React.FC = () => {

    const navigator = useNavigate();

    const {no} = useParams<{ no: string }>();

    const [formData, setFormData] = useRecoilState(locationUpdateFormState);


    const resetFormData = useResetRecoilState(locationUpdateFormState);

    useEffect(() => {
        resetFormData();
    }, [])



    useEffect(() => {
        if (no) {
            getLcDetail(parseInt(no));
        }
    }, [no])

    const getLcDetail = async (lcId: number) => {
        try {
            const result = await getLocationDetail(lcId);

            if (result.success && result.response) {
                const getData = result.response;
                setFormData({
                    siDo: getData.siDo,
                    siGunGu: getData.siGunGu,
                    eupMyeonDong: getData.eupMyeonDong,
                    ri: getData.ri,
                    code: getData.bcode
                })
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '지역 상세정보 가져오기',
                alert: true
            });
        }
    }

    const handleUpdateLocation = async () => {
        try {
            if (!no) return;
            const result = await updateLocation(parseInt(no), formData);

            if (result.success) {
                notificationAlert('알림', '수정이 완료되었습니다.');
                navigator(-1);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '지역 상세정보 가져오기',
                alert: true
            });
        }
    }


    return (
        <BasePage title={'지역 수정'}>
            <div className={'user_modify_page'}>
                <ul className={'info_list'}>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                지역 코드
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled type={'text'} name={'code'} placeholder={'지역 코드'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                시/도 주소
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'siDo'} placeholder={'시/도 주소'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                시/군/구 주소
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'siGunGu'} placeholder={'시/군/구 주소'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                읍/면/동 주소
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'eupMyeonDong'} placeholder={'읍/면/동 주소'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                리 주소
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'ri'} placeholder={'리 주소'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                </ul>
                <div className={'center_bottom_btn'}>
                    <button onClick={handleUpdateLocation}>수정</button>
                    <button onClick={() => navigator(-1)}>취소</button>
                </div>
            </div>
        </BasePage>
    )
}


export default LocationModifyPage;