import React, {useEffect, useState} from "react";
import {BasePage} from "@components/page/BasePage";
import classNames from "classnames";
import UserBaseInfoTab from "@pages/userManage/detail/UserBaseInfoTab";
import UserSellHistoryTab from "@pages/userManage/detail/UserSellHistoryTab";
import {useParams} from "react-router-dom";
import {presentationError} from "@/presentation/errors/presentationError";
import {getMemberDetail} from "@/domain/repository/MemberRepository";
import {useSetRecoilState} from "recoil";
import {memberDetailState} from "@pages/userManage/atoms";


const UserManageDetailPage: React.FC = () => {
    const [tabNum, setTabNum] = useState<number>(1);
    const setMemberDetail = useSetRecoilState(memberDetailState);
    const {no} = useParams<{ no: string }>();

    useEffect(() => {
        if (no) {
            getUser(parseInt(no));
        }
    }, [no])

    const getUser = async (memberId: number) => {
        try {
            const result = await getMemberDetail(memberId);

            if (result.success && result.response) {
                setMemberDetail(result.response);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '회원 정보 가져오기',
                alert: true
            });
        }
    }

    return (
        <BasePage title={'회원상세'}>
            <div className={'user_manage_detail_wrapper'}>
                <ul className={'status_tab horizon_scroll'}>
                    <li
                        className={classNames({
                            'active': tabNum === 1
                        })}>
                        <button onClick={() => setTabNum(1)}>
                            <span>기본정보</span>
                        </button>
                    </li>
                    <li
                        className={classNames({
                            'active': tabNum === 2
                        })}>
                        <button onClick={() => setTabNum(2)}>
                            <span>판매내역</span>
                        </button>
                    </li>
                </ul>
                {tabNum === 1 && <UserBaseInfoTab/>}
                {tabNum === 2 && <UserSellHistoryTab/>}
            </div>

        </BasePage>
    )
}


export default UserManageDetailPage;