import React, {useEffect} from "react";
import {BasePage} from "@components/page/BasePage";
import {Col, Row} from "react-bootstrap";
import Input from "@pages/userManage/form/modify/Input";
import Select from "@pages/userManage/form/modify/Select";
import {userRankOptions, useStatusOptions} from "@/presentation/data/options";
import DatePicker from "@pages/userManage/form/modify/DatePicker";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState, useResetRecoilState, useSetRecoilState} from "recoil";
import {createMemberFormState, memberUpdateState} from "@pages/userManage/atoms";
import {getMemberDetail, updateMember, updateMemberImage} from "@/domain/repository/MemberRepository";
import {presentationError} from "@/presentation/errors/presentationError";
import {notificationAlert} from "@/presentation/helpers/alert";


const UserModifyPage: React.FC = () => {

    const navigator = useNavigate();
    const [updateForm, setUpdateForm] = useRecoilState(memberUpdateState);
    const {no} = useParams<{ no: string }>();

    const resetFormData = useResetRecoilState(memberUpdateState);

    useEffect(() => {
        resetFormData();
    }, [])


    useEffect(() => {
        if (no) {
            getFormData(parseInt(no));
        }
    }, [no]);

    const handleUpdateMember = async () => {
        try {
            if (!no) return;
            if (!updateForm.name) {
                notificationAlert('알림', '이름을 입력하여 주세요.');
                return;
            }
            if (!updateForm.phoneNumber) {
                notificationAlert('알림', '휴대폰번호을 입력하여 주세요.');
                return;
            }
            if (!updateForm.nickname) {
                notificationAlert('알림', '닉네임을 입력하여 주세요.');
                return;
            }
            if (updateForm.password) {
                if (updateForm.password !== updateForm.passwordConfirm) {
                    notificationAlert('알림', '비밀번호가 일치하지 않습니다. 다시 확인하여 주세요.');
                    return;
                }
            }


            const result = await updateMember({
                memberId: parseInt(no),
                dto: updateForm
            });

            if (result.success) {
                getFormData(parseInt(no));
                notificationAlert('알림', '수정되었습니다.');
                navigator(-1);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '회원정보 수정',
                alert: true
            });
        }
    }

    const getFormData = async (memberId: number) => {
        try {
            const result = await getMemberDetail(memberId);

            if (result.success && result.response) {
                const formData = result.response;
                setUpdateForm(old => ({
                    ...old,
                    name: formData.name,
                    phoneNumber: formData.phoneNumber,
                    nickname: formData.nickName,
                    grade: formData.grade,
                    memberState: formData.memberState,
                    sanctionComment: formData.sanctionComment,
                    startSanctionDate: formData.startSanctionDate,
                    endSanctionDate: formData.endSanctionDate
                }))
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '회원 정보 가져오기',
                alert: true
            });
        }
    }

    const handleUpdateProfile = async (file: FileList | null) => {
        try {
            if (file && no) {
                const result = await updateMemberImage(no, file[0]);
                if (result.success) {
                    notificationAlert('알림', '프로필 이미지가 수정되었습니다.');
                }

            }
        } catch (err) {
            presentationError({
                error: err,
                type: '회원 프로필 수정',
                alert: true
            });
        }
    }


    return (
        <BasePage title={'회원정보 수정'}>
            <div className={'user_modify_page'}>
                <ul className={'info_list'}>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 이름
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'name'} placeholder={'이름'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * ID(휴대폰번호)
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'phoneNumber'} placeholder={'- 없이 입력하세요.'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 닉네임
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'nickname'} placeholder={'닉네임'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                프로필 사진
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <input type={'file'}
                                       accept="image/*"
                                       multiple={false}
                                       onChange={event => handleUpdateProfile(event.target.files)}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 비밀번호
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'password'} name={'password'} placeholder={'비밀번호'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 비밀번호 확인
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'password'} name={'passwordConfirm'} placeholder={'비밀번호 확인'}
                                       maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                회원등급
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Select name={'grade'} options={userRankOptions}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                상태
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Select name={'memberState'} options={useStatusOptions}/>
                                {updateForm.memberState === 'SANCTION' && <>
                                    <div className={'with_label_box'}>
                                        <label>제재사유</label>
                                        <div className={'form_content'}>
                                            <Input type={'text'} name={'sanctionComment'} placeholder={'제재사유'}
                                                   maxLength={255}/>
                                        </div>
                                    </div>
                                    <div className={'with_label_box'}>
                                        <label>제재기간</label>
                                        <div className={'form_content'}>
                                            <Row>
                                                <Col className={'p-0'} xs={5}>
                                                    <DatePicker name={'startSanctionDate'}/>
                                                </Col>
                                                <Col className={'p-0'} xs={2}>
                                                    <div className={'time_flow'}>
                                                        ~
                                                    </div>
                                                </Col>
                                                <Col className={'p-0'} xs={5}>
                                                    <DatePicker name={'endSanctionDate'}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </>
                                }
                            </Col>
                        </Row>
                    </li>
                </ul>
                <div className={'center_bottom_btn'}>
                    <button onClick={handleUpdateMember}>수정</button>
                    <button onClick={() => navigator(-1)}>취소</button>
                </div>
            </div>
        </BasePage>
    )
}


export default UserModifyPage;