import {AxiosHttpClient} from "@/main/api/axios-http-client";
import {API_ADDRESS} from "@/main/api/ApiFactory";
import {HttpStatusCode} from "@/main/api/http-client";
import {ApiError} from "@/domain/model/errors/ApiError";
import {IBaseResponse} from "@/domain/model/response/base-response-model";
import {UnexpectedError} from "@/domain/model/errors/unexpected-error";
import {BasicResponse} from "@/domain/model/response/BasicResponse";
import {LocalStorageAdapter} from "@/main/api/local-storage-adapter";
import {LoginResponse} from "@/domain/model/response/LoginResponse";
import {LoginRequest} from "@/domain/model/request/loginRequest";

export const login = async (request: LoginRequest): Promise<IBaseResponse<LoginResponse>> => {
    const client = await new AxiosHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/login',
        method: 'post',
        body: request
    })

    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: {
            const res = httpResponse.body as IBaseResponse<LoginResponse>;
            const storage = new LocalStorageAdapter()
            storage.set('token', {access: res.response?.accessToken})
            return res
        }
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const logout = async (): Promise<BasicResponse> => {
    const client = await new AxiosHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/auth/local',
        method: 'delete'
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: {
            const storage = new LocalStorageAdapter()
            const token = storage.get('token')
            if (token) {
                if (token.access) {
                    await storage.set('token')
                }
            }
            return httpResponse.body as BasicResponse
        }
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }

}
