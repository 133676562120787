import React, {useEffect} from "react";
import {BasePage} from "@components/page/BasePage";
import TableFilter from "@pages/location/TableFilter";
import LocationListTable from "@pages/location/LocationListTable";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {presentationError} from "@/presentation/errors/presentationError";
import {locationFilterState, locationListState} from "@pages/location/atoms";
import {getLocations} from "@/domain/repository/LocationRepository";


const LocationManagePage: React.FC = () => {

    const setQuestions = useSetRecoilState(locationListState);
    const filterState = useRecoilValue(locationFilterState);
    useEffect(() => {
        getLocationList();
    }, [filterState])

    const getLocationList = async () => {
        try {
            const result = await getLocations(filterState);

            if (result.success && result.response) {
                setQuestions(result.response);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '지역 가져오기',
                alert: true
            });
        }
    }

    return (
        <BasePage title={'지역 관리'}>
            <div className={'interest_item_manage_page'}>
                <TableFilter/>
                <LocationListTable/>
            </div>
        </BasePage>
    )
}


export default LocationManagePage;