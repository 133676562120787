import {AuthorizeHttpClient} from "@/main/api/authorize-http-client-decorator";
import {API_ADDRESS} from "@/main/api/ApiFactory";
import {HttpStatusCode} from "@/main/api/http-client";
import {ApiError} from "@/domain/model/errors/ApiError";
import {IBaseResponse} from "@/domain/model/response/base-response-model";
import {UnexpectedError} from "@/domain/model/errors/unexpected-error";
import {MemberResponse} from "@/domain/model/response/MemberResponse";
import {MemberRequest} from "@/domain/model/request/MemberRequest";
import {MemberDetailResponse} from "@/domain/model/response/MemberDetailResponse";
import {UpdateMemberRequest} from "@/domain/model/request/UpdateMemberRequest";
import {CreateMemberRequest} from "@/domain/model/request/CreateMemberRequest";
import {QuestionRequest} from "@/domain/model/request/QuestionRequest";
import {QuestionResponse} from "@/domain/model/response/QuestionResponse";
import {QuestionDetailResponse} from "@/domain/model/response/QuestionDetailResponse";

export const getQuestions = async (params: QuestionRequest): Promise<IBaseResponse<QuestionResponse>> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/question/all',
        method: 'get',
        params: params
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<QuestionResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const getQuestionDetail = async (qsId: number): Promise<IBaseResponse<QuestionDetailResponse>> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/question/${qsId}`,
        method: 'get',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<QuestionDetailResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}


export const deleteQuestion = async (qsId: number): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/question/${qsId}`,
        method: 'delete',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const deleteMultiQuestion = async (qsId: number[]): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/question`,
        method: 'delete',
        body: {questionIds: qsId}
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const updateQuestionState = async (qsId: string, state: string): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();


    const httpResponse = await client.request({
        url: API_ADDRESS + `/question/${qsId}/state`,
        method: 'put',
        headers: {
            'content-type': 'application/json'
        },
        body: state
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}
