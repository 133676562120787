import React, {useEffect} from "react";
import SellHistoryTable from "@pages/userManage/detail/SellHistoryTable";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberItemFilterState, memberItemListState, memberListState, userFilterState} from "@pages/userManage/atoms";
import {getMemberItems, getMembers} from "@/domain/repository/MemberRepository";
import {presentationError} from "@/presentation/errors/presentationError";
import {useParams} from "react-router-dom";
import TableFilter from "@pages/userManage/detail/TableFilter";


const UserSellHistoryTab: React.FC = () => {

    const {no} = useParams<{ no: string }>();
    const setMemberItems = useSetRecoilState(memberItemListState);
    const filterState = useRecoilValue(memberItemFilterState);
    useEffect(() => {
        getMemberItemList();
    }, [filterState])

    const getMemberItemList = async () => {
        try {
            if (!no) return;
            const result = await getMemberItems(parseInt(no), filterState);

            if (result.success && result.response) {
                setMemberItems(result.response)
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '유저 가져오기',
                alert: true
            });
        }
    }


    return (
        <div className={'user_sell_history_tab'}>
            <TableFilter/>
            <SellHistoryTable/>
        </div>
    )
}


export default UserSellHistoryTab;