import React, {useState} from "react";
import {Calendar} from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import dayjs from 'dayjs';

type IProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    state: any
    setState: any
    name: string
    direction?: string
    saveType?: string
};


const DatePickerBase: React.FC<IProps> = ({direction, state, setState, name, saveType}) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <div
            onBlur={(e) => {
                if (e.currentTarget === e.target) {
                    setOpen(false);
                }
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setOpen(false);
                }
            }}
            className={'date_picker_base_wrapper'}>
            <div className="dropdown bootstrap-select   default-select show">
                <button onClick={() => setOpen(old => !old)} className={'select_toggle'}>
                    <div style={{textAlign: 'left', fontSize: '0.875rem'}}>
                        {state[name] && dayjs(state[name]).format('YYYY-MM-DD')}
                    </div>
                </button>
                {open && <div
                    className={'date_picker_wrapper'}
                    style={
                        direction === 'right' ?
                            {
                                maxHeight: "434.406px",
                                overflow: "hidden",
                                minHeight: "0px",
                                position: "absolute",
                                willChange: "transform",
                                top: 0,
                                right: 0,
                                transform: "translate3d(0px, 44px, 0px)",
                                zIndex: 99
                            }
                            :
                            {
                                maxHeight: "434.406px",
                                overflow: "hidden",
                                minHeight: "0px",
                                position: "absolute",
                                willChange: "transform",
                                top: 0,
                                left: 0,
                                transform: "translate3d(-153px, 44px, 0px)",
                                zIndex: 99
                            }}>
                    <Calendar onChange={(item: Date) => {
                        if (saveType === 'date') {
                            setState({...state, [name]: dayjs(item).toDate()});
                        } else {
                            setState({...state, [name]: dayjs(item).format('YYYY-MM-DD')});
                        }
                        setOpen(false);
                    }}
                              locale={locales['ko']}
                              date={state[name] ? dayjs(state[name]).toDate() : new Date()}/>
                </div>}
            </div>
        </div>
    )
}

export default DatePickerBase