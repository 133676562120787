import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from "./main/routes/router";
import "@assets/scss/index.scss";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Router/>);

