import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Select from "@pages/salesPost/form/create/Select";
import {boardTypeOptions, tradeTypeOptions} from "@/presentation/data/options";
import Input from "@pages/salesPost/form/create/Input";
import {BasePage} from "@components/page/BasePage";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {createSalesPostFormState} from "@pages/salesPost/atoms";
import {createMember} from "@/domain/repository/MemberRepository";
import {notificationAlert} from "@/presentation/helpers/alert";
import {presentationError} from "@/presentation/errors/presentationError";
import {createSalesPost} from "@/domain/repository/SalesPostRepository";


const SalesPostCreatePage: React.FC = () => {
    const navigator = useNavigate();
    const [images, setImages] = useState<File[]>([]);
    const [formData, setFormData] = useRecoilState(createSalesPostFormState);

    const resetFormData = useResetRecoilState(createSalesPostFormState);

    useEffect(() => {
        resetFormData();
    },[])

    const handleCreateSalesPost = async () => {
        try {
            const result = await createSalesPost(formData);

            if (result.success) {
                notificationAlert('알림', '판매글이 추가되었습니다.');
                navigator('/salesPost')
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '판매글 생성',
                alert: true
            });
        }
    }

    const uploadFile = (file: FileList | null) => {
        if (file) {
            if (file.length > 10) {
                notificationAlert('알림', '이미지는 최대 10개만 등록가능 합니다.');
                return;
            }
            setFormData(old => ({...old, images: Array.from(file)}));
            setImages(Array.from(file));
        }
    }

    const deleteFile = (file: File) => {
        const newImages = images.filter(img => img !== file);
        setImages([...newImages]);
        setFormData(old => ({...old, images: [...newImages]}));
    }

    return (
        <BasePage title={'판매글 등록'}>
            <div className={'sales_post_create_page info_tab'}>
                <ul className={'info_list'}>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 거래종류
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Select name={'itemType'} options={tradeTypeOptions}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 거래 상태
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Select name={'itemState'} options={boardTypeOptions}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 판매자ID(휴대폰전화)
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'phoneNumber'} placeholder={'-없이 입력해 주세요.'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 매물위치(지역코드)
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'address'} placeholder={'매물위치(지역코드)'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                상세매물위치
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'detailAddress'} placeholder={'상세매물위치'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 사진
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <input type={'file'}
                                       accept="image/*"
                                       multiple
                                       onChange={event => uploadFile(event.target.files)}/>
                                <div className={'img_list_wrapper'}>
                                    {images.map(img => <p>{img.name}
                                        <button onClick={() => deleteFile(img)} className={'close'}/>
                                    </p>)}
                                </div>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 소개글
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'description'} placeholder={'소개글'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 매매가
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled={formData.itemType !== 'SALE'} type={'number'} name={'price'}
                                       placeholder={'매매가'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 전세
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled={formData.itemType !== 'JEONSE'} type={'number'} name={'price'}
                                       placeholder={'전세'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 월세
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled={!(formData.itemType === 'MONTHLY')} type={'number'}
                                       name={'monthlyRent'}
                                       placeholder={'월세'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 보증금
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input disabled={!(formData.itemType === 'MONTHLY')} type={'number'} name={'price'}
                                       placeholder={'보증금'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                권리금
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'number'} name={'keyMoney'} placeholder={'권리금'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                관리비
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'number'} name={'maintenanceFee'} placeholder={'관리비'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                * 실 평수
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'number'} name={'silPyeongSu'} placeholder={'실 평수'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                </ul>
                <div className={'center_bottom_btn'}>
                    <button onClick={handleCreateSalesPost}>등록</button>
                    <button onClick={() => navigator(-1)}>취소</button>
                </div>
            </div>
        </BasePage>
    )
}


export default SalesPostCreatePage;