import React from "react";
import {Link, useLocation} from "react-router-dom";
import {UserIcon} from "@components/icon/UserIcon";
import {CartIcon} from "@components/icon/CartIcon";
import {ChatIcon} from "@components/icon/ChatIcon";
import {HeartIcon} from "@components/icon/HeartIcon";
import {LocationIcon} from "@components/icon/LocationIcon";
import classNames from "classnames";


const LeftSideBar: React.FC = () => {

    const location = useLocation();

    return (
        <div className={'left_side_wrapper'}>
            <ul className={'side_menu'}>
                <li>
                    <Link className={classNames({
                        'active': (location.pathname === '/') || location.pathname.includes('user')
                    })}
                          to={'/'}>
                        <UserIcon width={20} height={20} fill={'#444444'}/>
                        <span>회원관리</span>
                    </Link>
                </li>
                <li>
                    <Link className={classNames({
                        'active': location.pathname.includes('salesPost')
                    })}
                          to={'/salesPost'}>
                        <CartIcon width={20} height={20} fill={'#444444'}/>
                        <span>판매글 관리</span>
                    </Link>
                </li>
                <li>
                    <Link
                        className={classNames({
                            'active': location.pathname.includes('question')
                        })}
                        to={'/question'}>
                        <ChatIcon width={20} height={20} fill={'#444444'}/>
                        <span>문의 관리</span>
                    </Link>
                </li>
                {/*<li>*/}
                {/*    <Link className={classNames({*/}
                {/*        'active': location.pathname.includes('interestItem')*/}
                {/*    })}*/}
                {/*          to={'/interestItem'}>*/}
                {/*        <HeartIcon width={20} height={20} fill={'#444444'}/>*/}
                {/*        <span>관심매물 관리</span>*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <li>
                    <Link className={classNames({
                        'active': location.pathname.includes('location')
                    })}
                          to={'/location'}>
                        <LocationIcon width={20} height={20} fill={'#444444'}/>
                        <span>지역 관리</span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}


export default LeftSideBar;
