export interface GetStorage {
  get: (key: string) => any
  getString: (key: string) => string
}

export interface SetStorage {
  set: (key: string, value: object) => void
}


export class LocalStorageAdapter implements SetStorage, GetStorage {
  set(key: string, value?: object): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }

  get(key: string): any {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }

  getString(key: string): string {
    return localStorage.getItem(key) || '';
  }

}
