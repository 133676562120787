import {AuthorizeHttpClient} from "@/main/api/authorize-http-client-decorator";
import {API_ADDRESS} from "@/main/api/ApiFactory";
import {HttpStatusCode} from "@/main/api/http-client";
import {ApiError} from "@/domain/model/errors/ApiError";
import {IBaseResponse} from "@/domain/model/response/base-response-model";
import {UnexpectedError} from "@/domain/model/errors/unexpected-error";
import {MemberResponse} from "@/domain/model/response/MemberResponse";
import {MemberRequest} from "@/domain/model/request/MemberRequest";
import {MemberDetailResponse} from "@/domain/model/response/MemberDetailResponse";
import {UpdateMemberRequest} from "@/domain/model/request/UpdateMemberRequest";
import {CreateMemberRequest} from "@/domain/model/request/CreateMemberRequest";
import {MemberItemRequest} from "@/domain/model/request/MemberItemRequest";
import {MemberItemResponse} from "@/domain/model/response/MemberItemResponse";

export const getMembers = async (params: MemberRequest): Promise<IBaseResponse<MemberResponse>> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/member/all',
        method: 'get',
        params: params
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<MemberResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const getMemberDetail = async (memberId: number): Promise<IBaseResponse<MemberDetailResponse>> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/member/${memberId}`,
        method: 'get',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<MemberDetailResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const createMember = async (request: CreateMemberRequest): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const formData = new FormData();
    formData.append('name', request.name);
    formData.append('phoneNumber', request.phoneNumber);
    formData.append('nickname', request.nickname);
    if (request.profileImage) {
        formData.append('profileImage', request.profileImage);
    }
    formData.append('password', request.password);
    formData.append('passwordConfirm', request.passwordConfirm);
    formData.append('grade', request.grade);

    const httpResponse = await client.request({
        url: API_ADDRESS + `/member`,
        method: 'post',
        body: formData
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const updateMember = async (request: UpdateMemberRequest): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/member/${request.memberId}`,
        method: 'put',
        body: request.dto
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const updateMemberImage = async (memberId: string, image: File): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const formData = new FormData();
    formData.append('memberId', memberId);
    formData.append('image', image);

    const httpResponse = await client.request({
        url: API_ADDRESS + `/member/${memberId}/image`,
        method: 'put',
        body: formData
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}


export const deleteMember = async (memberId: number): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/member/${memberId}`,
        method: 'delete',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const deleteMultiMember = async (memberIds: number[]): Promise<IBaseResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/member`,
        method: 'delete',
        body: {memberIds}
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}

export const getMemberItems = async (memberId: number,params: MemberItemRequest): Promise<IBaseResponse<MemberItemResponse>> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + `/member/${memberId}/item`,
        method: 'get',
        params: params
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as IBaseResponse<MemberItemResponse>
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).error.status, (httpResponse.body as IBaseResponse).error.message)
        default:
            throw new UnexpectedError()
    }
}