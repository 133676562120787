import Swal from "sweetalert2";


export const notificationAlert = (type: string, txt: string) => {
    Swal.fire({
        title: type,
        text: txt,
        confirmButtonText: '닫기'
    })
}

export const moveNotificationAlert = (type: string, txt: string, location: string) => {
    Swal.fire({
        allowOutsideClick: () => false,
        title: type,
        text: txt,
        confirmButtonText: '닫기'
    }).then(result => {
        if (result.isConfirmed) {
            window.location.href = location;
        }
    })
}