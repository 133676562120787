import React from 'react';
import {useRecoilState} from 'recoil';
import SelectBase, {ISelectItem} from "@components/select/SelectBase";
import {searchFilterState} from "@pages/location/atoms";

type Props = {
    name: string
    options: ISelectItem[]
    placeholder: string
};

const Select: React.FC<Props> = ({name, options,placeholder}: Props) => {
    const [state, setState] = useRecoilState(searchFilterState);

    return (
            <SelectBase
                placeholder={placeholder}
                className="select_base"
                name={name}
                state={state}
                setState={setState}
                options={options}/>
    );
};

export default Select;
