import {atom} from 'recoil'
import {QuestionResponse} from "@/domain/model/response/QuestionResponse";
import {QuestionRequest} from "@/domain/model/request/QuestionRequest";
import {QuestionDetailResponse} from "@/domain/model/response/QuestionDetailResponse";
import {v1} from 'uuid';

interface ISearchFilter {
    state?: string
    questionSearchType?: string
    word?: string
    startCreatedDate?: string
    endCreatedDate?: string
}

export const searchFilterState = atom<ISearchFilter>({
    key: `searchFilterState/${v1()}`,
    default: {
        state: 'ALL',
        questionSearchType: 'ALL'
    }
})

export const questionFilterState = atom<QuestionRequest>({
    key: `questionFilterState/${v1()}`,
    default: {
        state: 'ALL',
        questionSearchType: 'ALL',
        page: 1,
        size: 10,
        sortType: 'DESC',
        questionSortName: 'CREATED'
    }
})

export const questionListState = atom<QuestionResponse>({
    key: `questionListState/${v1()}`,
    default: {
        nowPage: 1,
        lastPage: 1,
        totalCnt: 0,
        questions: []
    }
})


export const questionDetailState = atom<QuestionDetailResponse>({
    key: 'questionDetailState',
    default: {
        questionId: 0,
        phoneNumber: '',
        email: '',
        itemId: 0,
        questionState: '',
        createdDate: new Date(),
        content: '',
        nickName: '',
    }
})


