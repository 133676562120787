import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import {isIterableArray} from "@/presentation/helpers/utility";

export type ISelectItem = {
    value: string
    txt: string
}

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    state: any
    setState: any
    name: string
    options?: ISelectItem[]
    valueLabel?: string
    useWrite?: boolean
    setInputMode?: (mode: boolean) => void
};

const SelectBase: React.FC<Props> = ({
                                         state,
                                         setState,
                                         name,
                                         options,
                                         valueLabel,
                                         useWrite,
                                         setInputMode,
                                         ...props
                                     }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [valLabel, setValLabel] = useState<string>('');

    useEffect(() => {
        if (state[name] || state[name] === "") {
            const val = options?.filter(op => op.value === state[name]);
            if (val) {
                if (isIterableArray(val)) {
                    setValLabel(val[0].txt);
                }
            }
        } else {
            setValLabel('');
        }
    }, [state[name], options])
    return (
        <div
            onBlur={(e) => {
                if (e.currentTarget === e.target) {
                    setOpen(false);
                }
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setOpen(false);
                }
            }}
            className="form-group">
            <div className="dropdown bootstrap-select form-control  default-select show">
                <select
                    className="form-control form-control-lg default-select" tabIndex={-98}>
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                </select>
                <button type="button"
                        className="btn select_toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-expanded="true"
                        onClick={() => setOpen(old => !old)}>
                    <div className="filter-option">
                        <div className="filter-option-inner">
                            <div className="filter-option-inner-inner">{valLabel ? valLabel : state[name]}</div>
                        </div>
                    </div>
                </button>
                {open &&
                    <div className="dropdown-menu show" role="combobox" x-placement="bottom-start"
                         style={{
                             maxHeight: "434.406px",
                             overflow: "hidden",
                             minHeight: "0px",
                             position: "absolute",
                             willChange: "transform",
                             top: 0,
                             left: 0,
                             transform: "translate3d(0px, 44px, 0px)"
                         }}>
                        {options &&
                            <div className="inner show" role="listbox" aria-expanded="true" tabIndex={-1}
                                 style={{maxHeight: "418.406px", overflowY: "auto", minHeight: "0px"}}>
                                <ul className="dropdown-menu inner show">
                                    {options.map(option => <li
                                        key={option.value}
                                        onClick={() => {
                                            setState({...state, [name]: option.value});
                                            setInputMode && setInputMode(false);
                                            setOpen(false);
                                        }}
                                        className={classNames({
                                            "selected": state[name] === option.value,
                                            "active": state[name] === option.value
                                        })}>
                                        <a role="option" className="dropdown-item selected active"
                                           aria-disabled="false" tabIndex={0}
                                           aria-selected="true">
                                    <span
                                        className="text">{option.txt}</span></a></li>)}
                                    {useWrite &&
                                        <li onClick={() => {
                                            setInputMode && setInputMode(true);
                                            setOpen(false);
                                        }
                                        }>
                                            <a role="option" className="dropdown-item selected active"
                                               aria-disabled="false" tabIndex={0}
                                               aria-selected="true">
                                                <span className="text">
                                                    직접 입력
                                                </span>
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
        // <div style={{textAlign: 'left', width: '100%'}}>
        //     <select
        //         {...props}
        //         ref={inputRef}
        //         data-testid={name}
        //         onChange={(e) => {
        //             setState({...state, [e.target.name]: e.target.value});
        //         }}
        //     >
        //         {options.map(option => <option key={option.value}>{option.txt}</option>)}
        //     </select>
        // </div>
    );
};

export default SelectBase;
