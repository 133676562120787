import React from "react";
import {Col, Row} from "react-bootstrap";
import {presentationError} from "@/presentation/errors/presentationError";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {memberDetailState} from "@pages/userManage/atoms";
import {getMemberStateKor, getStringDate} from "@/presentation/helpers/utility";
import {deleteMember} from "@/domain/repository/MemberRepository";
import {notificationAlert} from "@/presentation/helpers/alert";
import {PROFILE_IMAGE_URL} from "@/main/api/ApiFactory";



const UserBaseInfoTab: React.FC = () => {
    const navigator = useNavigate();
    const {no} = useParams<{ no: string }>();
    const [memberDetail, setMemberDetail] = useRecoilState(memberDetailState);
    const handleDelUser = async () => {
        try {
            if (!no) return;
            Swal.fire({
                title: '정말 회원를 삭제 하시겠습니까?',
                confirmButtonText: '삭제',
                showCancelButton: true,
                cancelButtonText: '취소'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await deleteMember(parseInt(no));
                    if (result.success) {
                        notificationAlert('알림', '삭제되었습니다.');
                        navigator(-1);
                    }
                }
            })
        } catch (err) {
            presentationError({
                error: err,
                type: '회원 삭제',
                alert: true
            });
        }
    }

    return (
        <div className={'info_tab'}>
            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '1rem'}}>
                <button onClick={() => navigator(`/user/${no}/modify`)} id={'modify_btn'}>수정</button>
                <button onClick={handleDelUser} id={'del_btn'}>삭제</button>
            </div>
            <ul className={'info_list'}>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            이름
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {memberDetail.name}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            ID(휴대폰번호)
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {memberDetail.phoneNumber}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            닉네임
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {memberDetail.nickName}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            회원등급
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {memberDetail.grade}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            프로필 사진
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            <a href={`${PROFILE_IMAGE_URL}/${memberDetail.storeImageName}`} target={'_blank'}>
                            {`${PROFILE_IMAGE_URL}/${memberDetail.storeImageName}`}
                            </a>
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            가입일
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {getStringDate(memberDetail.signUpDate)}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            최근 접속일
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {getStringDate(memberDetail.lastLoginDate)}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            상태
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {getMemberStateKor(memberDetail.memberState)}
                        </Col>
                    </Row>
                </li>
            </ul>

        </div>
    )
}


export default UserBaseInfoTab;