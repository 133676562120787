import React from 'react';
import {useRecoilState} from 'recoil';
import SelectBase, {ISelectItem} from "@components/select/SelectBase";
import {createSalesPostFormState} from "@pages/salesPost/atoms";

type Props = {
    name: string
    options: ISelectItem[]
};

const Select: React.FC<Props> = ({name, options}: Props) => {
    const [state, setState] = useRecoilState(createSalesPostFormState);

    return (
            <SelectBase
                className="select_base"
                name={name}
                state={state}
                setState={setState}
                options={options}/>
    );
};

export default Select;
