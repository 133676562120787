import React, {useEffect, useRef, useState} from 'react';
import {isIterableArray} from "@/presentation/helpers/utility";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    state: any
    setState: any
    type: string
    name: string
    placeholder: string
};

const isNotNumber = (value: string) => {
    const regExp = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
    return regExp.test(value);
}

const InputBase: React.FC<Props> = ({state, setState, type, name, placeholder, ...props}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const error = state[`${name}Error`];
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        setValue('');
    }, [])

    useEffect(() => {
        if (name.includes('.')) {
            const splitName = name.split('.');
            if (isIterableArray(splitName)) {
                const firstObj = state[splitName[0]];
                if (firstObj) {
                    if (type === 'number') {
                        setValue(parseInt(firstObj[splitName[1]]).toLocaleString());
                    } else {
                        setValue(firstObj[splitName[1]]);
                    }
                }

            }
        } else {
            if (state[name]) {
                if (type === 'number') {
                    setValue(parseInt(state[name]).toLocaleString());
                } else {
                    setValue(String(state[name]));
                }
            } else {
                setValue('');
            }
        }
    }, [state, name])

    return (
        <div className={'input_base'} style={{textAlign: 'left', width: '100%'}}>
            <input
                {...props}
                value={(props.disabled && name === 'price') ? '' : value}
                type={type === "number" ? "text" : type}
                ref={inputRef}
                name={name}
                title={error}
                data-testid={name}
                readOnly
                onFocus={(e) => {
                    e.target.readOnly = false;
                }}
                placeholder={placeholder}
                onChange={(e) => {
                    if (type === "number") {
                        if (e.target.value && isNotNumber(e.target.value)) {
                            e.preventDefault();
                            return;
                        }
                    }
                    if (e.target.name.includes('.')) {
                        const splitName = e.target.name.split('.');
                        if (isIterableArray(splitName)) {
                            setState({
                                ...state,
                                [splitName[0]]: {
                                    ...state[splitName[0]],
                                    [splitName[1]]: e.target.value
                                }
                            });
                            setValue(e.target.value);
                        }
                    } else {
                        if (type === 'number') {
                            if (!e.target.value) {
                                setState({...state, [name]: 0});
                                setValue('');
                                return;
                            }
                            const removedCommaValue: number = Number(e.target.value.replaceAll(",", ""));
                            setState({...state, [name]: removedCommaValue});
                            setValue(removedCommaValue.toLocaleString());
                        } else {
                            setState({...state, [name]: e.target.value});
                            setValue(e.target.value);
                        }
                    }
                }}
            />
            <div style={{padding: '0 0.3rem'}}>
                <small style={{color: 'red'}}>{error}</small>
            </div>
        </div>
    );
};

export default InputBase;
