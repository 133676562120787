import React from 'react';
import {useRecoilState} from 'recoil';
import DatePickerBase from "@components/datePicker/DatePickerBase";
import {searchFilterState} from "@pages/question/atoms";

type Props = {
    name: string
};

const DatePicker: React.FC<Props> = ({name}: Props) => {
    const [state, setState] = useRecoilState(searchFilterState);

    return (
            <DatePickerBase name={name} state={state} setState={setState}/>
    );
};

export default DatePicker;
