import React, {useEffect} from "react";
import Select from "@pages/userManage/form/filter/Select";
import {filterSearchTypeOptions, filterStatusOptions} from "@/presentation/data/options";
import Input from "@pages/userManage/form/filter/Input";
import {Col, Row} from "react-bootstrap";
import DatePicker from "@pages/userManage/form/filter/DatePicker";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {searchFilterState, userFilterState} from "@pages/userManage/atoms";


const TableFilter: React.FC = () => {

    const resetFilter = useResetRecoilState(userFilterState);
    const resetSearchFilter = useResetRecoilState(searchFilterState);
    const setFilter = useSetRecoilState(userFilterState);
    const searchFilter = useRecoilValue(searchFilterState);

    const handleSearch = () => {
        setFilter(old => ({
            ...old,
            ...searchFilter
        }))
    }

    const handleReset = () => {
        resetFilter();
        resetSearchFilter();
    }

    useEffect(() => {
        handleReset();
    }, [])


    return (
        <div className={'table_filter'}>
            <ul className={'filter_options'}>
                <li>
                    <div className={'option_name'}>상태</div>
                    <Select name={'state'} options={filterStatusOptions}/>
                </li>
                <li>
                    <div className={'option_name'}>검색어</div>
                    <Row className={'flex-grow-1'}>
                        <Col xs={3} style={{paddingRight: 0}}>
                            <Select name={'searchType'} options={filterSearchTypeOptions}/>
                        </Col>
                        <Col xs={9} style={{paddingLeft: 0}}>
                            <Input type={'text'} name={'word'} placeholder={''} maxLength={255}/>
                        </Col>
                    </Row>
                </li>
                <li>
                    <div className={'option_name'}>가입일</div>
                    <Row className={'flex-grow-1'}>
                        <Col xs={5} style={{paddingRight: 0}}>
                            <DatePicker name={'startSignUp'}/>
                        </Col>
                        <Col xs={2}>
                            <div className={'time_flow'}>
                                ~
                            </div>
                        </Col>
                        <Col xs={5} style={{paddingLeft: 0}}>
                            <DatePicker name={'endSignUp'}/>
                        </Col>
                    </Row>
                </li>
                <li>
                    <div className={'option_name'}>최근 접속일</div>
                    <Row className={'flex-grow-1'}>
                        <Col xs={5} style={{paddingRight: 0}}>
                            <DatePicker name={'startLastLogin'}/>
                        </Col>
                        <Col xs={2}>
                            <div className={'time_flow'}>
                                ~
                            </div>
                        </Col>
                        <Col xs={5} style={{paddingLeft: 0}}>
                            <DatePicker name={'endLastLogin'}/>
                        </Col>
                    </Row>
                </li>
            </ul>
            <div className={'filter_btns'}>
                <button onClick={handleSearch} id={'search_btn'}>검색</button>
                <button onClick={handleReset} id={'init_btn'}>초기화</button>
            </div>
        </div>
    )
}

export default TableFilter;