import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {presentationError} from "@/presentation/errors/presentationError";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import {BasePage} from "@components/page/BasePage";
import {useRecoilState} from "recoil";
import {questionDetailState} from "@pages/question/atoms";
import {getQuestionDetail} from "@/domain/repository/QuestionRepository";
import {locationDetailState} from "@pages/location/atoms";
import {deleteLocation, getLocationDetail} from "@/domain/repository/LocationRepository";
import {notificationAlert} from "@/presentation/helpers/alert";


const LocationDetailPage: React.FC = () => {
    const navigator = useNavigate();
    const {no} = useParams<{ no: string }>();

    const [lcDetail, setlocDetail] = useRecoilState(locationDetailState)
    useEffect(() => {
        if (no) {
            getLcDetail(parseInt(no));
        }
    }, [no])

    const getLcDetail = async (lcId: number) => {
        try {
            const result = await getLocationDetail(lcId);

            if (result.success && result.response) {
                setlocDetail(result.response);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '지역 상세정보 가져오기',
                alert: true
            });
        }
    }


    const handleDelUser = async () => {
        try {
            Swal.fire({
                title: '정말 지역을 삭제 하시겠습니까?',
                confirmButtonText: '삭제',
                showCancelButton: true,
                cancelButtonText: '취소'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await deleteLocation(lcDetail.bcode);

                    if (result.success) {
                        notificationAlert('알림', '삭제되었습니다.');
                        navigator(-1);
                    }
                }
            })
        } catch (err) {
            presentationError({
                error: err,
                type: '지역 삭제',
                alert: true
            });
        }
    }


    return (
        <BasePage title={'지역 상세'}>
            <div className={'qs_detail_wrapper'}>
                <div className={'info_tab'}>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '1rem'}}>
                        <button onClick={() => navigator(`/location/${no}/modify`)} id={'modify_btn'}>수정</button>
                        <button onClick={handleDelUser} id={'del_btn'}>삭제</button>
                    </div>
                    <ul className={'info_list'}>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    법정동 코드
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.bcode}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    시/도 코드
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.bcode.slice(0,2)}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    시/군/구 코드
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.bcode.slice(2,5)}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    읍/면/동 코드
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.bcode.slice(5,8)}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    리 코드
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.bcode.slice(8,10)}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    시/도 주소
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.siDo}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    시/군/구 주소
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.siGunGu}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    읍/면/동 주소
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.eupMyeonDong}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    리 주소
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {lcDetail.ri}
                                </Col>
                            </Row>
                        </li>
                    </ul>
                </div>
            </div>
        </BasePage>
    )
}


export default LocationDetailPage;