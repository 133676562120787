import React from "react";
import {Col, Row} from "react-bootstrap";
import {presentationError} from "@/presentation/errors/presentationError";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import {deleteMember} from "@/domain/repository/MemberRepository";
import {notificationAlert} from "@/presentation/helpers/alert";
import {deleteSalesPost} from "@/domain/repository/SalesPostRepository";
import {useRecoilValue} from "recoil";
import {salesPostDetailState} from "@pages/salesPost/atoms";
import {getMemberItemStateKor, getMemberItemTypeKor, isIterableArray} from "@/presentation/helpers/utility";
import {ITEM_IMAGE_URL} from "@/main/api/ApiFactory";


const SalesPostInfoTab: React.FC = () => {
    const navigator = useNavigate();
    const {no} = useParams<{ no: string }>();
    const salesPostDetail = useRecoilValue(salesPostDetailState);
    const handleDelUser = async () => {
        try {
            if (!no) return;
            Swal.fire({
                title: '정말 매물을 삭제 하시겠습니까?',
                confirmButtonText: '삭제',
                showCancelButton: true,
                cancelButtonText: '취소'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await deleteSalesPost(parseInt(no));
                    if (result.success) {
                        notificationAlert('알림', '삭제되었습니다.');
                        navigator(-1);
                    }
                }
            })
        } catch (err) {
            presentationError({
                error: err,
                type: '매물 삭제',
                alert: true
            });
        }
    }


    return (
        <div className={'info_tab'}>
            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '1rem'}}>
                <button onClick={() => navigator(`/salesPost/${no}/modify`)} id={'modify_btn'}>수정</button>
                <button onClick={handleDelUser} id={'del_btn'}>삭제</button>
            </div>
            <ul className={'info_list'}>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            거래종류
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {getMemberItemTypeKor(salesPostDetail.itemType)}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            게시글 상태
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {getMemberItemStateKor(salesPostDetail.itemState)}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            판매자ID(휴대폰전화)
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.phoneNumber}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            매물위치
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.mainAddress}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            상세매물위치
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.detailAddress}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            사진
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            <div className={'item_image_wrapper'}>
                                {isIterableArray(salesPostDetail.images) && salesPostDetail.images.map(img => <a
                                    href={`${ITEM_IMAGE_URL}/${img}`} target={'_blank'}>
                                    {img}
                                </a>)}
                            </div>
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            소개글
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.description}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            매매가
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.itemType === 'SALE' ? salesPostDetail.price.toLocaleString() : '-'}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            전세
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.itemType === 'JEONSE' ? salesPostDetail.price.toLocaleString() : '-'}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            월세
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.monthlyRent === 0 ? '-' : salesPostDetail.monthlyRent.toLocaleString()}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            보증금
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.monthlyRent === 0 ? '-' : salesPostDetail.price.toLocaleString()}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            권리금
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.keyMoney.toLocaleString()}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            관리비
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.maintenanceFee.toLocaleString()}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            실 평수
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.silPyeongSu}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            조회수
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.views.toLocaleString()}
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row>
                        <Col xs={3} className={'info_name'}>
                            관심 매물 지정 유저 수
                        </Col>
                        <Col xs={9} className={'info_value'}>
                            {salesPostDetail.likeCnt.toLocaleString()}
                        </Col>
                    </Row>
                </li>
            </ul>

        </div>
    )
}


export default SalesPostInfoTab;