import React, {ReactNode} from "react";
import TopNavBar from "@components/layout/TopNavBar";
import LeftSideBar from "@components/layout/LeftSideBar";

interface IProps {
    children: ReactNode
}

const MainLayout: React.FC<IProps> = ({children}) => {


    return (
        <div className={'main_layout_wrapper'}>
            <TopNavBar/>
            <LeftSideBar/>
            <div className={'content_container'}>
                {children}
            </div>
        </div>
    )
}


export default MainLayout;
