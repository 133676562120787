import React, {useEffect, useState} from "react";
import {BasePage} from "@components/page/BasePage";
import classNames from "classnames";
import UserBaseInfoTab from "@pages/userManage/detail/UserBaseInfoTab";
import UserSellHistoryTab from "@pages/userManage/detail/UserSellHistoryTab";
import SalesPostInfoTab from "@pages/salesPost/detail/SalesPostInfoTab";
import {useParams} from "react-router-dom";
import {getMemberDetail} from "@/domain/repository/MemberRepository";
import {presentationError} from "@/presentation/errors/presentationError";
import {useSetRecoilState} from "recoil";
import {salesPostDetailState} from "@pages/salesPost/atoms";
import {getSalesPostDetail} from "@/domain/repository/SalesPostRepository";


const SalesPostDetailPage: React.FC = () => {

    const [tabNum, setTabNum] = useState<number>(1);
    const {no} = useParams<{ no: string }>();
    const setSalesPostDetail = useSetRecoilState(salesPostDetailState)
    useEffect(() => {
        if (no) {
            getPostDetail(parseInt(no));
        }
    }, [no])

    const getPostDetail = async (postId: number) => {
        try {
            const result = await getSalesPostDetail(postId);

            if (result.success && result.response) {
                setSalesPostDetail(result.response);

            }
        } catch (err) {
            presentationError({
                error: err,
                type: '매물 정보 가져오기',
                alert: true
            });
        }
    }

    return (
        <BasePage title={'판매글 상세'}>
            <div className={'sales_post_detail_wrapper'}>
                <ul className={'status_tab horizon_scroll'}>
                    <li
                        className={classNames({
                            'active': tabNum === 1
                        })}>
                        <button onClick={() => setTabNum(1)}>
                            <span>매물정보</span>
                        </button>
                    </li>
                </ul>
                {tabNum === 1 && <SalesPostInfoTab/>}
            </div>

        </BasePage>
    )
}


export default SalesPostDetailPage;