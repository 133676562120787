import {atom} from 'recoil'
import {LocationResponse} from "@/domain/model/response/LocationResponse";
import {LocationRequest} from "@/domain/model/request/LocationRequest";
import {LocationDetailResponse} from "@/domain/model/response/LocationDetailResponse";
import {CreateLocationRequest} from "@/domain/model/request/CreateLocationRequest";
import {v1} from 'uuid';

interface ISearchFilter {
    siDo?: string | null
    siGunGu?: string | null
    word?: string
}

export const searchFilterState = atom<ISearchFilter>({
    key: `searchFilterState/${v1()}`,
    default: {
        siDo: '',
        siGunGu: '',
        word: '',
    }
})

export const locationFilterState = atom<LocationRequest>({
    key: `locationFilterState/${v1()}`,
    default: {
        siDo: '',
        siGunGu: '',
        word: '',
        page: 1,
        size: 10
    }
})

export const locationListState = atom<LocationResponse>({
    key: `locationListState/${v1()}`,
    default: {
        nowPage: 1,
        lastPage: 1,
        totalCnt: 0,
        addresses: []
    }
})


export const locationDetailState = atom<LocationDetailResponse>({
    key: `locationDetailState/${v1()}`,
    default: {
        siDo: '',
        siGunGu: '',
        eupMyeonDong: '',
        ri: '',
        createdDate: new Date(),
        bcode: '',
    }
})

export const locationCreateFormState = atom<CreateLocationRequest>({
    key: `locationCreateFormState/${v1()}`,
    default: {
        siDo: '',
        siGunGu: '',
        eupMyeonDong: '',
        ri: '',
        code: ''
    }
})

export const locationUpdateFormState = atom<CreateLocationRequest>({
    key: `locationUpdateFormState/${v1()}`,
    default: {
        siDo: '',
        siGunGu: '',
        eupMyeonDong: '',
        ri: '',
        code: ''
    }
})
