import React, {useEffect} from "react";
import {BasePage} from "@components/page/BasePage";
import TableFilter from "@pages/salesPost/TableFilter";
import SalesPostListTable from "@pages/salesPost/SalesPostListTable";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {memberListState, userFilterState} from "@pages/userManage/atoms";
import {getMembers} from "@/domain/repository/MemberRepository";
import {presentationError} from "@/presentation/errors/presentationError";
import {salesPostFilterState, salesPostListState} from "@pages/salesPost/atoms";
import {getSalesPosts} from "@/domain/repository/SalesPostRepository";


const SalesPostPage: React.FC = () => {

    const setPosts = useSetRecoilState(salesPostListState);
    const filterState = useRecoilValue(salesPostFilterState);

    const resetFilterState = useResetRecoilState(salesPostFilterState);

    useEffect(() => {
        resetFilterState();
    }, [])

    useEffect(() => {
        getPostsList();
    }, [filterState])

    const getPostsList = async () => {
        try {
            console.log(filterState)
            const result = await getSalesPosts(filterState);

            if (result.success && result.response) {
                setPosts(result.response)
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '판매글 가져오기',
                alert: true
            });
        }
    }

    return (
        <BasePage title={'판매글 관리'}>
            <div className={'sales_post_page'}>
                <TableFilter/>
                <SalesPostListTable/>
            </div>
        </BasePage>
    )
}


export default SalesPostPage;