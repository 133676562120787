export class BaseError extends Error {
    readonly errorCode: number
    readonly message: string

    constructor (errorCode: number, message: string) {
        super('Access Denied')
        this.name = 'BaseError'
        this.errorCode = errorCode
        this.message = message
    }
}
