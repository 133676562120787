import React, {useEffect} from "react";
import {BasePage} from "@components/page/BasePage";
import {Col, Row} from "react-bootstrap";
import Input from "@pages/location/form/create/Input";
import {useNavigate} from "react-router-dom";
import {presentationError} from "@/presentation/errors/presentationError";
import {useRecoilValue, useResetRecoilState} from "recoil";
import {locationCreateFormState} from "@pages/location/atoms";
import {createLocation} from "@/domain/repository/LocationRepository";
import {notificationAlert} from "@/presentation/helpers/alert";


const LocationCreatePage: React.FC = () => {

    const navigator = useNavigate();
    const formData = useRecoilValue(locationCreateFormState);

    const resetFormData = useResetRecoilState(locationCreateFormState);

    useEffect(() => {
        resetFormData();
    }, [])


    const handleCreateLocation = async () => {
        try {
            const result = await createLocation(formData);
            if (result.success) {
                notificationAlert('알림', '지역이 추가되었습니다.');
                navigator(-1);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '지역 추가',
                alert: true
            });
        }
    }

    return (
        <BasePage title={'지역 추가'}>
            <div className={'user_modify_page'}>
                <ul className={'info_list'}>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                지역 코드
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'code'} placeholder={'지역 코드'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                시/도 주소
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'siDo'} placeholder={'시/도 주소'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                시/군/구 주소
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'siGunGu'} placeholder={'시/군/구 주소'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                읍/면/동 주소
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'eupMyeonDong'} placeholder={'읍/면/동 주소'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={3} className={'info_name'}>
                                리 주소
                            </Col>
                            <Col xs={9} className={'info_value'}>
                                <Input type={'text'} name={'ri'} placeholder={'리 주소'} maxLength={255}/>
                            </Col>
                        </Row>
                    </li>
                </ul>
                <div className={'center_bottom_btn'}>
                    <button onClick={handleCreateLocation}>추가</button>
                    <button onClick={() => navigator(-1)}>취소</button>
                </div>
            </div>
        </BasePage>
    )
}


export default LocationCreatePage;