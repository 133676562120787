import * as React from 'react';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import {visuallyHidden} from '@mui/utils';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {memberListState, userFilterState} from "@pages/userManage/atoms";
import {getMemberStateKor, isIterableArray} from "@/presentation/helpers/utility";
import {Link, useNavigate} from "react-router-dom";
import {presentationError} from "@/presentation/errors/presentationError";
import ReactPaginate from "react-paginate";
import {notificationAlert} from "@/presentation/helpers/alert";
import {deleteMember, deleteMultiMember} from "@/domain/repository/MemberRepository";
import Swal from "sweetalert2";


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'ASC' | 'DESC';


interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
    sortId: string
}

const headCells: readonly HeadCell[] = [
    {
        id: 'memberId',
        numeric: false,
        disablePadding: true,
        label: '번호',
        sortId: 'ID'
    },
    {
        id: 'memberState',
        numeric: true,
        disablePadding: false,
        label: '상태',
        sortId: 'STATE'
    },
    {
        id: 'authName',
        numeric: true,
        disablePadding: false,
        label: '회원등급',
        sortId: 'AUTHORITY'
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: '이름',
        sortId: 'NAME'
    },
    {
        id: 'nickname',
        numeric: true,
        disablePadding: false,
        label: '닉네임',
        sortId: 'NICKNAME'
    },
    {
        id: 'phoneNumber',
        numeric: true,
        disablePadding: false,
        label: 'ID(휴대폰번호)',
        sortId: 'PHONE'
    },
    {
        id: 'createdData',
        numeric: true,
        disablePadding: false,
        label: '가입일',
        sortId: 'SIGNUP'
    },
    {
        id: 'lastLoginDate',
        numeric: true,
        disablePadding: false,
        label: '최근 접속일',
        sortId: 'LASTLOGIN'
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
        props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.sortId ? order === 'ASC' ? 'asc' : 'desc' : false}
                    >
                        {headCell.sortId ?
                            <TableSortLabel
                                active={orderBy === headCell.sortId}
                                direction={orderBy === headCell.sortId ? order === 'ASC' ? 'asc' : 'desc' : 'asc'}
                                onClick={createSortHandler(headCell.sortId)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>

                            :
                            <>
                                {headCell.label}
                            </>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function UserListTable() {
    const navigator = useNavigate();
    const [order, setOrder] = React.useState<Order>('ASC');
    const [orderBy, setOrderBy] = React.useState<string>('memberId');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [dense, setDense] = React.useState(false);
    const [membersState, setMemberState] = useRecoilState(memberListState);
    const setFilterState = useSetRecoilState(userFilterState);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'ASC';
        setFilterState(old => ({
            ...old,
            sortName: property,
            sortType: isAsc ? 'DESC' : 'ASC'
        }));
        setOrderBy(property);
        setOrder(isAsc ? 'DESC' : 'ASC')
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = membersState.members.map((n) => String(n.memberId));
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleClickPage = (page: { selected: number }) => {
        setFilterState(old => ({
            ...old,
            page: page.selected + 1
        }));
    }

    const handleDeleteSelectMember = async () => {
        try {
            if (!isIterableArray(selected)) {
                notificationAlert('알림', '삭제하실 항목을 선택하여 주세요.');
                return;
            }
            Swal.fire({
                title: '정말 회원를 삭제 하시겠습니까?',
                confirmButtonText: '삭제',
                showCancelButton: true,
                cancelButtonText: '취소'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await deleteMultiMember(selected.map(s => parseInt(s)));

                    if (result.success) {
                        let newMembers = membersState.members.filter(m => !selected.includes(String(m.memberId)));
                        setMemberState(old => ({...old, members: [...newMembers]}));
                        setSelected([]);
                        notificationAlert('알림', '삭제되었습니다.');

                    }

                }
            })

        } catch (err) {
            presentationError({
                error: err,
                type: '회원 삭제',
                alert: true
            });
        }
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1;


    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                <TableContainer>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={membersState.members.length}
                        />
                        <TableBody>
                            {membersState.members
                                .map((row, index) => {
                                    const isItemSelected = isSelected(String(row.memberId));
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, String(row.memberId))}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.memberId}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align={'center'}
                                            >
                                                {row.memberId}
                                            </TableCell>
                                            <TableCell align="center">{getMemberStateKor(row.memberState)}</TableCell>
                                            <TableCell align="center">{row.authName}</TableCell>
                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">{row.nickname}</TableCell>
                                            <TableCell align="center">
                                                <Link to={`/user/${row.memberId}`}>
                                                    {row.phoneNumber}
                                                </Link>
                                            </TableCell>
                                            <TableCell
                                                align="center">{row.createdDate && dayjs(row.createdDate).format('YY.MM.DD HH:mm')}</TableCell>
                                            <TableCell
                                                align="center">{row.lastLoginDate && dayjs(row.lastLoginDate).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div className={'paginate_wrapper'}>
                <ReactPaginate
                    className={'paginate'}
                    breakLabel="..."
                    nextLabel="❯"
                    onPageChange={handleClickPage}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(membersState.totalCnt / 10)}
                    previousLabel="❮"
                    // renderOnZeroPageCount={null}
                />
            </div>
            <div className={'bottom_btn'}>
                <button onClick={handleDeleteSelectMember}>선택항목 삭제</button>
                <button onClick={() => navigator('/user/add')}>회원추가</button>
            </div>
        </Box>
    );
}
