import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {presentationError} from "@/presentation/errors/presentationError";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import {BasePage} from "@components/page/BasePage";
import {useRecoilState, useSetRecoilState} from "recoil";
import {salesPostDetailState} from "@pages/salesPost/atoms";
import {getSalesPostDetail} from "@/domain/repository/SalesPostRepository";
import {questionDetailState} from "@pages/question/atoms";
import {deleteQuestion, getQuestionDetail, updateQuestionState} from "@/domain/repository/QuestionRepository";
import {notificationAlert} from "@/presentation/helpers/alert";
import {getQuestionStateKor, getStringDateTime} from "@/presentation/helpers/utility";


const QuestionDetailPage: React.FC = () => {
    const navigator = useNavigate();
    const {no} = useParams<{ no: string }>();
    const [questionDetail, setQuestionDetail] = useRecoilState(questionDetailState)
    useEffect(() => {
        if (no) {
            getQsDetail(parseInt(no));
        }
    }, [no])

    const getQsDetail = async (postId: number) => {
        try {
            const result = await getQuestionDetail(postId);

            if (result.success && result.response) {
                setQuestionDetail(result.response);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '매물 정보 가져오기',
                alert: true
            });
        }
    }

    const handleDelUser = async () => {
        try {
            if (!no) return;
            Swal.fire({
                title: '정말 문의를 삭제 하시겠습니까?',
                confirmButtonText: '삭제',
                showCancelButton: true,
                cancelButtonText: '취소'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await deleteQuestion(parseInt(no));

                    if (result.success) {
                        notificationAlert('알림', '삭제되었습니다.');
                        navigator(-1);
                    }
                }
            })
        } catch (err) {
            presentationError({
                error: err,
                type: '문의 삭제',
                alert: true
            });
        }
    }

    const handleChangeStatus = async () => {
        try {
            if (!no) return;
            const {value: status} = await Swal.fire({
                title: '문의 상태변경',
                input: 'radio',
                inputOptions: {
                    'WAIT': '처리대기',
                    'COMPLETE': '완료',
                },

            })

            if (status) {
                const result = await updateQuestionState(no, status);
                if (result.success) {
                    setQuestionDetail(old => ({...old, questionState: status}));
                    notificationAlert('알림', '상태가 변경되었습니다.');
                }
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '문의 상태 변경',
                alert: true
            });
        }
    }

    return (
        <BasePage title={'문의 상세'}>
            <div className={'qs_detail_wrapper'}>
                <div className={'info_tab'}>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '1rem'}}>
                        <button onClick={handleDelUser} id={'del_btn'}>삭제</button>
                    </div>
                    <ul className={'info_list'}>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    연락처
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {questionDetail.phoneNumber}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    이메일
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {questionDetail.email}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    매물번호
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {questionDetail.itemId}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    상태
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    <div className={'flex_row_between'}>
                                        {getQuestionStateKor(questionDetail.questionState)}
                                        <button onClick={handleChangeStatus} id={'change_status_btn'}>상태변경</button>
                                    </div>
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    등록일
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {getStringDateTime(questionDetail.createdDate)}
                                </Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col xs={3} className={'info_name'}>
                                    문의내용
                                </Col>
                                <Col xs={9} className={'info_value'}>
                                    {questionDetail.content}
                                </Col>
                            </Row>
                        </li>
                    </ul>
                </div>
            </div>
        </BasePage>
    )
}


export default QuestionDetailPage;