import React from "react";
import {HomeIcon} from "@components/icon/HomeIcon";
import {LogoutIcon} from "@components/icon/LogoutIcon";
import logo from '@assets/image/mainLogo.png';
import {Link} from "react-router-dom";
import {LocalStorageAdapter} from "@/main/api/local-storage-adapter";

const TopNavBar: React.FC = () => {

    const handleLogout = async () => {
        const storage = new LocalStorageAdapter()
        const token = storage.get('token')
        if (token) {
            if (token.access) {
                await storage.set('token');
                await storage.set('propt_AATh');
                window.location.href = '/login';
                return;
            }
        }

        await storage.set('propt_AATh');
        window.location.href = '/login';
    }

    return (
        <div className={'top_nav_wrapper'}>
            <div className={'logo_box'}>
                <Link to={'/'}>
                    <img src={logo} alt={'logo_img'}/>
                </Link>
            </div>
            <ul className={'user_section'}>
                <li>
                    <HomeIcon width={20} height={20} fill={'#5F5F5F'}/>
                </li>
                <li>
                    <p>홈페이지 : <span>admin</span>님이 로그인 하셨습니다.</p>
                </li>
                <li onClick={handleLogout} style={{cursor: 'pointer'}}>
                    <LogoutIcon width={20} height={20} fill={'#5F5F5F'}/>
                </li>
            </ul>
        </div>
    )
}


export default TopNavBar;
